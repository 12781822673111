import React, {useEffect, useState} from "react";
import styles from "./rvHero.module.sass";
import RvLocationInput from "../rvLocationInput";
import RvDateRangePicker from "../rvDateRangePicker";
import { getSearchUrlByAddressLatLng } from "../../util/url";

const RvHero = ({ submit }) => {
  const [locationData, setLocationData] = useState(null);
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const locationChanged = (data) => {
    setLocationData(data);
  }

  const dateRangeChanged = (data) => {
    if (data?.startDate)
      setStartDate(data.startDate);
    if (data?.endDate)
      setEndDate(data.endDate);
  }

  const submitButtonClicked = async () => {
    let url = null;
    url = await getSearchUrlByAddressLatLng({ address: locationData?.label, lat: locationData?.lat, lng: locationData?.lng, startDate: startDate?.format("YYYY-MM-DD"), endDate: endDate?.format("YYYY-MM-DD") });
    submit(url);
  }

  useEffect(() => {
    if (locationData)
      setIsLocationChanged(true);
  }, [locationData])

  return (
    <div className={styles.heroImg}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.findRV}>
              <div>
                <h3>

                </h3>
                <h3>Find the <span className={styles.txtColor}>Perfect</span> RV Rental</h3>
              </div>
              <div>
                <div className="form-group">
                  <RvLocationInput onChange={locationChanged} />
                </div>
              </div>
              <div>
                <div className="form-group">
                  <RvDateRangePicker
                    isLocationChanged={isLocationChanged}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={dateRangeChanged}
                  />
                </div>
              </div>
              <div>
                <div className="form-group">
                  <button
                    className={styles.submitBtn}
                    onClick={submitButtonClicked}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RvHero