import {
  RVS_SEARCH_FETCH,
  RVS_SEARCH_FETCH_SUCCESS,
  RVS_SEARCH_FETCH_FAILURE,
  RVS_POPULAR_NEAR_BY_FETCH,
  RVS_POPULAR_NEAR_BY_FETCH_SUCCESS,
  RVS_POPULAR_NEAR_BY_FETCH_FAILURE,
  RVS_ITEM_FETCH,
  RVS_ITEM_FETCH_SUCCESS,
  RVS_ITEM_FETCH_FAILURE, RVS_ITEM_BOOK, RVS_ITEM_BOOK_SUCCESS, RVS_ITEM_BOOK_FAILURE
} from "../constants/actionType";

const initState = {
  data: null,
  popularNearByData: null,
  item: null,
  loadFlag: false,
  success: null,
  errorMessage: null
}

const rvReducer = (state, action) => {
  state = state || initState;

  switch (action.type) {
    case RVS_SEARCH_FETCH:
      return Object.assign({}, state, {
        loadFlag: true,
        data: null,
        errorMessage: null,
      });

    case RVS_SEARCH_FETCH_SUCCESS:
      return Object.assign({}, state, {
        loadFlag: false,
        errorMessage: null,
        data: action.data,
      });

    case RVS_SEARCH_FETCH_FAILURE:
      return Object.assign({}, state, {
        loadFlag: false,
        data: null,
        errorMessage: action.error
      });

    case RVS_POPULAR_NEAR_BY_FETCH:
      return Object.assign({}, state, {
        popularNearByData: null
      });

    case RVS_POPULAR_NEAR_BY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        popularNearByData: action.data
      });

    case RVS_POPULAR_NEAR_BY_FETCH_FAILURE:
      return Object.assign({}, state, {
        popularNearByData: null
      });

    case RVS_ITEM_FETCH:
      return Object.assign({}, state, {
        item: null,
        loadFlag: true
      });

    case RVS_ITEM_FETCH_SUCCESS:
      return Object.assign({}, state, {
        item: action.data,
        loadFlag: false
      });

    case RVS_ITEM_FETCH_FAILURE:
      return Object.assign({}, state, {
        loadFlag: false,
        errorMessage: action.error
      })

    case RVS_ITEM_BOOK:
      return Object.assign({}, state, {
        loadFlag: true,
        success: null,
        errorMessage: null
      })

    case RVS_ITEM_BOOK_SUCCESS:
      return Object.assign({}, state, {
        loadFlag: false,
        success: true,
        errorMessage: null
      })

    case RVS_ITEM_BOOK_FAILURE:
      return Object.assign({}, state, {
        loadFlag: false,
        success: false,
        errorMessage: action.error
      })

    default:
      return state;
  }
}

export default rvReducer