import React, { useState } from "react";
import cn from "classnames";
import Switch from "react-switch";
import styles from "./morePopup.module.sass";
import RangeSlider from "../rangeSlider";
import { MdPets } from "react-icons/md";
import {
  GiReptileTail,
  GiCigarette,
  GiPowerGenerator
} from "react-icons/gi";
import {
  BsMusicNoteBeamed,
  BsStopwatch
} from "react-icons/bs";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FcElectricity } from "react-icons/fc";

const trailerWeight = {
  min: 0,
  max: 10000
};

const RATING_ICON_SIZE = 30;
const RATING_ICON_COLOR = "#1fa1a1";
const ELECTRIC_ICON_SIZE = 30;
const SWITCH_OFF_COLOR = "#ccc";
const SWITCH_OFF_HANDLER_COLOR = "#fff";

const MorePopup = ({ className }) => {
  const [trailerWeightMin, setTrailerWeightMin] = useState(0);
  const [trailerWeightMax, setTrailerWeightMax] = useState(10000);
  const [bookInstantly, setBookInstantly] = useState(false);
  const [feetFirst, setFeetFirst] = useState(false);
  const [feetSecond, setFeetSecond] = useState(false);
  const [feetThird, setFeetThird] = useState(false);
  const [feetFourth, setFeetFourth] = useState(false);
  const [petFriendly, setPetFriendly] = useState(false);
  const [tailgateFriendly, setTailgateFriendly] = useState(false);
  const [festivalFriendly, setFestivalFriendly] = useState(false);
  const [smokingAllowed, setSmokingAllowed] = useState(false);
  const [unlimitedGenerator, setUnlimitedGenerator] = useState(false);
  const [unlimitedMileage, setUnlimitedMileage] = useState(false);
  const [flexible, setFlexible] = useState(false);
  const [moderator, setModerator] = useState(false);
  const [rating, setRating] = useState(0);
  const [keySearch, setKeySearch] = useState("");

  return (
    <div className={cn(className, styles.main)}>
      <div className={styles.content}>
        <div className={styles.body}>
          <div className={styles.list}>
            <div className={styles.items}>
              <fieldset className={styles.item}>
                <h5 className={styles.category}>Vehicle details</h5>
                <div className={styles.group}>
                  <p className={styles.title}>LENGTH</p>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          18 - 24 feet
                        </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={feetFirst}
                          checked={feetFirst}
                          onChange={(e) => setFeetFirst(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          25 - 31 feet
                        </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={feetSecond}
                          checked={feetSecond}
                          onChange={(e) => setFeetSecond(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          32 - 38 feet
                        </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={feetThird}
                          checked={feetThird}
                          onChange={(e) => setFeetThird(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          39+ feet
                        </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={feetFourth}
                          checked={feetFourth}
                          onChange={(e) => setFeetFourth(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.group}>
                  <p className={styles.title}>TRAILER WEIGHT</p>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <RangeSlider
                        min={trailerWeight.min}
                        max={trailerWeight.max}
                        onChange={({ min, max }) => {
                          setTrailerWeightMin(min);
                          setTrailerWeightMax(max);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className={styles.item}>
                <h5 className={styles.category}>Temperature</h5>
                <div className={styles.group}>
                  <p className={styles.title}>RULES</p>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <MdPets
                            className={styles.labelIcon}
                            size={22}
                          />
                          Pet-friendly
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={petFriendly}
                          checked={petFriendly}
                          onChange={(e) => setPetFriendly(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiReptileTail
                            className={styles.labelIcon}
                            size={22}
                          />
                          Tailgate-friendly
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={tailgateFriendly}
                          checked={tailgateFriendly}
                          onChange={(e) => setTailgateFriendly(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <BsMusicNoteBeamed
                            className={styles.labelIcon}
                            size={22}
                          />
                          Festival-friendly
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={festivalFriendly}
                          checked={festivalFriendly}
                          onChange={(e) => setFestivalFriendly(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiCigarette
                            className={styles.labelIcon}
                            size={22}
                          />
                          Smoking allowed
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={smokingAllowed}
                          checked={smokingAllowed}
                          onChange={(e) => setSmokingAllowed(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className={styles.item}>
                <h5 className={styles.category}>&nbsp;</h5>
                <div className={styles.group}>
                  <p className={styles.title}>POLICIES</p>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiPowerGenerator
                            className={styles.labelIcon}
                            size={22}
                          />
                          Unlimited generator
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={unlimitedGenerator}
                          checked={unlimitedGenerator}
                          onChange={(e) => setUnlimitedGenerator(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <BsStopwatch
                            className={styles.labelIcon}
                            size={22}
                          />
                          Unlimited mileage
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={unlimitedMileage}
                          checked={unlimitedMileage}
                          onChange={(e) => setUnlimitedMileage(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.group}>
                  <p className={styles.title}>CANCELLATION</p>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          Flexible
                        </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={flexible}
                          checked={flexible}
                          onChange={(e) => setFlexible(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          Moderate
                        </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={moderator}
                          checked={moderator}
                          onChange={(e) => setModerator(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <hr className={styles.border} />
            <div className={styles.items}>
              <fieldset className={styles.item}>
                <h5 className={styles.category}>Keyword search</h5>
                <p className={styles.description}>Search multiple keywords by using a comma.</p>
                <input
                  className={styles.txtInput}
                  type="text"
                  placeholder="Brand, model, make..."
                  value={keySearch}
                  onChange={(e) => setKeySearch(e.target.value)}
                />
              </fieldset>
              <fieldset className={styles.item}>
                <h5 className={styles.category}>Minimum rating</h5>
                <div className={styles.ratingWrap}>
                  {Array.from(Array(5), (e, i) => {
                    if (i + 1 <= rating) {
                      return (
                        <AiFillStar
                          key={i}
                          className={styles.ratingIcon}
                          size={RATING_ICON_SIZE}
                          color={RATING_ICON_COLOR}
                          onClick={() => setRating(i + 1)}
                        />
                      )
                    } else {
                      return (
                        <AiOutlineStar
                          key={i}
                          className={styles.ratingIcon}
                          size={RATING_ICON_SIZE}
                          color={RATING_ICON_COLOR}
                          onClick={() => setRating(i + 1)}
                        />
                      )
                    }
                  })}
                </div>
                <p className={styles.ratingText}>0 Star</p>
              </fieldset>
              <fieldset className={styles.item}>
                <h5 className={styles.category}>
                  Book instantly
                  <FcElectricity size={ELECTRIC_ICON_SIZE} />
                  <Switch
                    offColor={SWITCH_OFF_COLOR}
                    offHandleColor={SWITCH_OFF_HANDLER_COLOR}
                    onColor={RATING_ICON_COLOR}
                    checked={bookInstantly}
                    value={bookInstantly}
                    onChange={(value) => {
                      setBookInstantly(value);
                    }}
                  />
                </h5>

              </fieldset>
            </div>
          </div>
          <hr className={styles.border} />
        </div>
        <div className={styles.footer}>
          <button className={styles.btn}>
            No results
          </button>
          <span
            className={styles.txt}
            onClick={() => {
              setTrailerWeightMin(0);
              setTrailerWeightMax(0);
              setBookInstantly(false);
              setFeetFirst(false);
              setFeetSecond(false);
              setFeetThird(false);
              setFeetFourth(false);
              setPetFriendly(false);
              setTailgateFriendly(false);
              setFestivalFriendly(false);
              setSmokingAllowed(false);
              setUnlimitedGenerator(false);
              setUnlimitedMileage(false);
              setFlexible(false);
              setModerator(false);
              setRating(0);
              setKeySearch("");
            }}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default MorePopup