export const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST || "127.0.0.1";

export const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT || "8000";

export const BACKEND_API = "api"

export const API_URL = `${BACKEND_HOST}:${BACKEND_PORT}/${BACKEND_API}/`;

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=en&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

export const GOOGLE_RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`;

export const FACEBOOK_SOCIAL_URL = `${process.env.REACT_APP_FACEBOOK_SOCIAL_URL}`;

export const FRESH_DESK_NEW_TICKET_URL = `${process.env.REACT_APP_FRESH_DESK_NEW_TICKET_URL}`;

export const FRESH_DESK_HELP_URL = `${process.env.REACT_APP_FRESH_DESK_HELP_URL}`;

export const GOOGLE_ANALYTICS_TRACKING_ID = `${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;

export const ADMIN_NAME = "admin";

export const ADMIN_PASS = "admin";