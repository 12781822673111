import { useLocation } from "react-router-dom";
import { getCurrentLocation } from "../services/locationService";
import moment from "moment";

export const getSearchUrlByAddressLatLng = async ({ address, lat, lng, startDate, endDate }) => {
  let addressRef = address;
  let latRef = lat;
  let lngRef = lng;
  if (!address) {
    await getCurrentLocation().then(result => {
      addressRef = `${result.city}, ${result.country}`;
      latRef = result.latitude;
      lngRef = result.longitude;
    })
  }

  return `search?address=${addressRef}&lat=${latRef}&lng=${lngRef}&startDate=${startDate}&endDate=${endDate}`;
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const getAddressFromUrl = (query) => {
  return !query ? null : !query.get("address") || query.get("address") === "undefined" || query.get("address") === "null" ? null : query.get("address");
}

export const getLatFromUrl = (query) => {
  return !query ? null : !query.get("lat") || query.get("lat") === "undefined" || query.get("lat") === "null" ? null : Number(query.get("lat"));
}

export const getLngFromUrl = (query) => {
  return !query ? null : !query.get("lng") || query.get("lng") === "undefined" || query.get("lng") === "null" ? null : Number(query.get("lng"));
}

export const getStartDateFromUrl = (query) => {
  return !query ? null : !query.get("startDate") || query.get("startDate") === "undefined" || query.get("startDate") === "null" ? null : moment(query.get("startDate"));
}

export const getEndDateFromUrl = (query) => {
  return !query ? null : !query.get("endDate") || query.get("endDate") === "undefined" || query.get("endDate") === "null" ? null : moment(query.get("endDate"));
}

export const getSno = (query) => {
  return query ? query.get("sno") : null;
}

export const getQParams = (query) => {
  let qParams = {};
  for (const param of query) {
    if (param[0].includes("q"))
      qParams[param[0]] = param[1];
  }
  return qParams;
}