import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {reducer as formReducer} from 'redux-form';

import rvReducer from "./rvReducer";
import resReducer from "./resReducer";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    rvs: rvReducer,
    res: resReducer
  })

const rootReducer = (state, action) => {
  return appReducer(state, action);
}

export default rootReducer