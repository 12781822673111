import React, {useState} from "react";
import cn from "classnames";
import styles from "./pricePopup.module.sass";
import "react-rangeslider/lib/index.css";
import RangeSlider from "../rangeSlider";

const initMin = 0;
const initMax = 500;

const PricePopup = ({ className }) => {
  const [inputMin, setInputMin] = useState(initMin);
  const [inputMax, setInputMax] = useState(initMax);

  return (
    <div className={cn(className, styles.main)}>
      <div className={styles.content}>
        <div className={styles.body}>
          <div className={styles.valueWrap}>
            <input
              type="number"
              className={styles.value}
              value={inputMin}
              onChange={(e) => {
                setInputMin(Number(e.target.value));
              }}
            />
            &nbsp;&nbsp;
            <span>-</span>
            &nbsp;&nbsp;
            <input
              type="number"
              className={styles.value}
              value={inputMax}
              onChange={(e) => {
                setInputMax(e.target.value);
              }}
            />
          </div>
          <RangeSlider
            min={initMin}
            max={initMax}
            onChange={({ min, max }) => {
              setInputMin(min);
              setInputMax(max);
            }}
          />
          <hr className={styles.border} />
        </div>
        <div className={styles.footer}>
          <button className={styles.btn}>
            No results
          </button>
          <span
            className={styles.txt}
            onClick={() => {

            }}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default PricePopup