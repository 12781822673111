import React, {useEffect, useState} from 'react';
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import ReactPaginate from "react-paginate";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import styles from "./search.module.sass";
import "./paginate.sass";
import RvHeader from "../../components/rvHeader";
import RvFooter from "../../components/rvFooter";
import SubHeader from "../../components/subHeader";
import RvNewMap from "../../components/rvNewMap/rvNewMap";
import RvCard from "../../components/rvCard";
import RvLoadCard from "../../components/rvLoadCard/rvLoadCard";
import {
  getAddressFromUrl,
  getLatFromUrl,
  getLngFromUrl,
  getStartDateFromUrl,
  getEndDateFromUrl,
  useQuery,
  getSearchUrlByAddressLatLng
} from "../../util/url";
import { GOOGLE_RECAPTCHA_KEY } from "../../config/config";
import * as rvsSearchService from "../../services/rvService";
import { createTicket } from "../../services/ticketService";
import { loadScriptByURL } from "../../util/recaptcha";
import sentImg from "../../assets/img/sent.svg";

const pageTitle = "Rentzly - Search";

const Search = ({ rvs, loadFlag, errorMessage, actions }) => {
  const query = useQuery();
  const history = useHistory();
  const [location, setLocation] = useState(null);
  const [sortType, setSortType] = useState("distance");
  const [priceSort, setPriceSort] = useState(true);
  const [distanceSort, setDistanceSort] = useState(true);
  const [email, setEmail] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);

  const sendTicket = (e) => {
    e.preventDefault();
    if (!email || !location.address)
      return;

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, { action: 'submit' }).then(token => {
        createTicket({ type: "search", email, location: location.address, recaptchaToken: token }).then(() => {
          setTicketStatus(true);
        }).catch(() => {
          setTicketStatus(false);
        })
      });
    });
  }

  const searchRvs = () => {
    if (query) {
      window.scroll(0, 0);

      const address = getAddressFromUrl(query);
      const lat = getLatFromUrl(query);
      const lng = getLngFromUrl(query);
      const start = getStartDateFromUrl(query);
      const end = getEndDateFromUrl(query);

      setLocation({ address: address, lat: lat, lng: lng });

      actions.searchRVS({lat: lat, lng: lng, startDate: start?.format("YYYY-MM-DD"), endDate: end?.format("YYYY-MM-DD"), priceSort, distanceSort, pageIndex, sortType});

      ReactGA.event({
        category: "Search",
        action: "User Searched"
      });
    }
  }

  useEffect(() => {
    if (rvs?.pagination?.totalCount && rvs?.pagination?.pageSize)
      setPageCount(Math.ceil(rvs.pagination.totalCount / rvs.pagination.pageSize));
  }, [rvs])

  useEffect(() => {
    searchRvs();
  }, [distanceSort, priceSort, sortType])

  useEffect(() => {
    searchRvs();
  }, [pageIndex])

  useEffect(() => {
    setPageIndex(0);
    setPageCount(null);
    searchRvs();
  }, [history.location])

  useEffect(() => {
    document.title = pageTitle;
    window.scroll(0, 0);

    loadScriptByURL();
  }, [])

  return (
    <div>
      <RvHeader />
      <SubHeader
        priceSort={priceSort}
        distanceSort={distanceSort}
        setPriceSort={(value) => setPriceSort(value)}
        setDistanceSort={(value) => setDistanceSort(value)}
      />
      <div className={styles.main}>
        <div className={styles.left}>
          {!loadFlag &&
            ((errorMessage || !rvs || !rvs.data || rvs.data.length === 0) &&
              <div className={styles.errorWrap}>
                <p className={styles.description}>
                  Hmm... As of now, We don’t have anything available that matches your criteria. Don’t worry. We work every day to expand and provide rentals to more locations. If you want us to be notified when we have something in your location, enter your email address
                </p>
                <form onSubmit={(e) => {sendTicket(e)}}>
                  <label className={cn("d-flex", "bg-white", styles.label)}>
                    <input
                      type="email"
                      name="email"
                      required
                      value={email || ""}
                      onChange={(e) => setEmail(e.target.value)}
                      className={cn("form-control", styles.input)}
                      placeholder="Enter your email"
                    />
                    <button type="submit" className={styles.btn}>
                      <img src={sentImg} alt="sent" />
                    </button>
                  </label>
                </form>
                {ticketStatus === true &&
                  <p className={styles.success}>Ticket sent to the supporter.</p>
                }
                {ticketStatus === false &&
                  <p className={styles.error}>Failed to send ticket.</p>
                }
              </div>
            )
          }
          <div>
            {loadFlag ?
              <div className={styles.header}>
                <div className={styles.sortWrap}>
                  {distanceSort === true &&
                    <span className={styles.sort} onClick={() => {
                      setDistanceSort(false)
                      setSortType("distance");
                    }}>
                      Distance High To Low <IoMdArrowDropdown />
                    </span>
                  }
                  {distanceSort === false &&
                    <span className={styles.sort} onClick={() => {
                      setDistanceSort(true);
                      setSortType("distance");
                    }}>
                      Distance Low To High <IoMdArrowDropup />
                    </span>
                  }
                  {priceSort === true &&
                    <span className={styles.sort} onClick={() => {
                      setPriceSort(false);
                      setSortType("price");
                    }}>
                      Price High To Low <IoMdArrowDropdown />
                    </span>
                  }
                  {priceSort === false &&
                    <span className={styles.sort} onClick={() => {
                      setPriceSort(true);
                      setSortType("price");
                    }}>
                      Price Low To High <IoMdArrowDropup />
                    </span>
                  }
                </div>
                <span>0 RVs found</span>
              </div> :
              (!errorMessage && rvs?.data?.length > 0 &&
                <div className={styles.header}>
                  <div className={styles.sortWrap}>
                    {distanceSort === true &&
                      <span className={styles.sort} onClick={() => {
                        setDistanceSort(false)
                        setSortType("distance");
                      }}>
                        Distance High To Low <IoMdArrowDropdown />
                      </span>
                    }
                    {distanceSort === false &&
                      <span className={styles.sort} onClick={() => {
                        setDistanceSort(true);
                        setSortType("distance");
                      }}>
                        Distance Low To High <IoMdArrowDropup />
                      </span>
                    }
                    {priceSort === true &&
                      <span className={styles.sort} onClick={() => {
                        setPriceSort(false);
                        setSortType("price");
                      }}>
                        Price High To Low <IoMdArrowDropdown />
                      </span>
                    }
                    {priceSort === false &&
                      <span className={styles.sort} onClick={() => {
                        setPriceSort(true);
                        setSortType("price");
                      }}>
                        Price Low To High <IoMdArrowDropup />
                      </span>
                    }
                  </div>
                  <span>{rvs?.data?.length} RVs found</span>
                </div>)
            }
            <ul className={styles.searchList}>
              {loadFlag &&
                [...Array(16)].map((item, index) => {
                  return (
                    <li key={index}>
                      <RvLoadCard />
                    </li>
                  )
                })
              }
              {!loadFlag && rvs?.data?.map((item, index) => {
                return (
                  <li key={index}>
                    <RvCard
                      data={item}
                      onClick={() => {
                        window.open(`/detail/${item?.Listing_id}?startDate=${query.get("startDate")}&endDate=${query.get("endDate")}`, "_blank");
                      }}
                    />
                  </li>
                )
              })}
            </ul>
            {pageCount ?
              <div className={styles.paginationWrap}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  previousLabel="<"
                  onPageChange={(e) => {
                    setPageIndex(e.selected + 1);
                  }}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  breakClassName={"break-me"}
                />
                {rvs?.pagination && rvs?.data &&
                <div>
                  {(rvs.pagination.page - 1) * rvs.pagination.pageSize + 1} ~ {(rvs.pagination.page - 1) * rvs.pagination.pageSize + rvs.data.length} of {rvs.pagination.totalCount} total results
                </div>
                }
              </div> : ""
            }
          </div>
        </div>
        <div className={styles.right}>
          <RvNewMap
            loadingElement={<div style={{height: "100%"}}></div>}
            containerElement={<div style={{height: "100%"}}></div>}
            mapElement={<div style={{height: "100%"}}></div>}
            location={location}
            data={rvs}
            centerChanged={async (data) => {
              if (!data?.lat || !data?.lng)
                return;

              const address = getAddressFromUrl(query);
              const startDate = getStartDateFromUrl(query);
              const endDate = getEndDateFromUrl(query);

              const url = await getSearchUrlByAddressLatLng({
                address: address,
                lat: data.lat,
                lng: data.lng,
                startDate: startDate?.format("YYYY-MM-DD"),
                endDate: endDate?.format("YYYY-MM-DD")
              });

              if (url)
                history.push(url);
            }}
          />
        </div>
      </div>
      <RvFooter />
    </div>
  )
}

const mapStateToProps = (state) => ({
  rvs: state.rvs.data,
  loadFlag: state.rvs.loadFlag,
  errorMessage: state.rvs.errorMessage
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, rvsSearchService), dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)