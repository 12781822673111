import React, {useEffect, useState} from "react";
import RvCollapse from "../rvCollapse";
import styles from "./rvDetailSpecs.module.sass";
import cn from "classnames";
import {AiOutlineInfoCircle, AiOutlineUsergroupAdd} from "react-icons/ai";
import {BiBed, BiGasPump} from "react-icons/bi";
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
import {GiFuelTank, GiChemicalTank, GiWaterTank, GiScubaTanks} from "react-icons/gi";
import {IoWaterOutline} from "react-icons/io5";
import {MdTransform} from "react-icons/md";

const ICON_SIZE = 40;

const listData = [
  {key: "sleeps", parentKey: null, name: "Sleeps", unit: "guests", icon: <BiBed className="mr-3" size={ICON_SIZE} key={1} />},
  {key: "seatbelts", parentKey: null, name: "Seats", unit: "seatbelts", icon: <AiOutlineUsergroupAdd size={ICON_SIZE} key={2} />},
  {key: "water_tank", parentKey: "features", name: "Fresh water tank", unit: "gal", icon: <IoWaterOutline size={ICON_SIZE} key={3} />},
  {key: "fuel_type", parentKey: "features", name: "Fuel", unit: null, icon: <BiGasPump size={ICON_SIZE} key={4} />},
  {key: "fuel_tank", parentKey: "features", name: "Fuel capacity", unit: "gal", icon: <GiFuelTank size={ICON_SIZE} key={5} />},
  {key: "transmission", parentKey: "features", name: "Transmission", unit: null, icon: <MdTransform size={ICON_SIZE} key={6} />},
  {key: "gray_tank", parentKey: "features", name: "Gray Water", unit: "gal", icon: <GiChemicalTank size={ICON_SIZE} key={7} />},
  {key: "black_water", parentKey: "features", name: "Black Water", unit: "gal", icon: <GiWaterTank size={ICON_SIZE} key={8} />},
  {key: "propane_tank", parentKey: "features", name: "Propane Capacity", unit: "lbs", icon: <GiScubaTanks size={ICON_SIZE} key={9} />},
];

const initCount = 3;

const RvDetailSpecs = ({ rv }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [list, setList] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    let newList = [];

    if (rv) {
      newList = listData.map((item) => {
        if (item.parentKey) {
          if (rv[item.parentKey] && rv[item.parentKey][item.key]) {
            return {...item, ...{value: rv[item.parentKey][item.key]}};
          }
        } else {
          if (rv[item.key]) {
            return {...item, ...{value: rv[item.key]}};
          }
        }
      });

      newList = newList.filter(item => {
        return item !== undefined && item !== null;
      });

      setList(newList);
    }
  }, [rv])

  return (
    <RvCollapse
      trigger={
        <div className={styles.collapseHeader}>
          <div>
            {rv?.filtered_name &&
              <h4 className="font-weight-bold">{rv.filtered_name}</h4>
            }
            <p className={styles.labelList}>
              {rv?.display_vehicle_type &&
                <span>
                  {rv.display_vehicle_type}
                  <span>&nbsp;|&nbsp;</span>
                </span>
              }
              {rv?.Listing_ID &&
                <span>
                  Rental #{rv.Listing_ID}
                  <span>&nbsp;|&nbsp;</span>
                </span>
              }
              <span className={cn("d-flex align-items-center", styles.colorLabel)}>
                <AiOutlineInfoCircle className="mr-1" /> Report this listing
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center">
            {!isOpened &&
              <div>
                {list?.map((item, index) => {
                  if (index < initCount)
                    return (
                      item.icon
                    )
                })}
                {list?.length > initCount &&
                  <span className="font-weight-bold mr-2">+{list.length - initCount}</span>
                }
              </div>
            }
            <div className={styles.arrowBtn}>
              {isOpened ?
                <BsChevronUp size={18} /> :
                <BsChevronDown size={18} />
              }
            </div>
          </div>
        </div>
      }
      setIsOpened={setIsOpened}
      body={
        <div className={styles.collapseBody}>
          {rv?.filtered_description &&
            <p>
              {rv?.filtered_description}
            </p>
          }
          <div className={styles.blocksWrap}>
            {list?.map((item, index) => {
              if (index < initCount + 1)
                return (
                  <div className={styles.block} key={index}>
                    {item.icon}
                    <div>
                      <h6 className={styles.title}>{item.name}</h6>
                      <p className={styles.value}>
                        {(item.value && item.unit) &&
                          item.value + " " + item.unit
                        }
                        {(item.value && !item.unit) &&
                          item.value.charAt(0).toUpperCase() + item.value.slice(1)
                        }
                      </p>
                    </div>
                  </div>
                )
            })}
          </div>
          {(list?.length > initCount + 1 && isShowMore === false) &&
            <p className={styles.link} onClick={() => setIsShowMore(true)}>
              Show all specs
              <BsChevronDown className="ml-1"/>
            </p>
          }
          {(list?.length > initCount + 1 && isShowMore === true) &&
            <p className={styles.link} onClick={() => setIsShowMore(false)}>
              Hide all specs
              <BsChevronUp className="ml-1" />
            </p>
          }
          {isShowMore === true &&
            <div className={cn(styles.blocksWrap, "mt-3")}>
              {list.map((item, index) => {
                if (index > initCount)
                  return (
                    <div className={styles.block} key={index}>
                      {item.icon}
                      <div>
                        <h6 className={styles.title}>{item.name}</h6>
                        <p className={styles.value}>
                          {(item.value && item.unit) &&
                          item.value + " " + item.unit
                          }
                          {(item.value && !item.unit) &&
                          item.value.charAt(0).toUpperCase() + item.value.slice(1)
                          }
                        </p>
                      </div>
                    </div>
                  )
              })}
            </div>
          }
        </div>
      }
    />
  )
}

export default RvDetailSpecs