import moment from "moment";

export const isBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

export const isInclusivelyAfterDay = (a) => {
  const b = moment();
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

export const isBlocked = (day, dates) => {
  return dates ? dates.some(date => day.isSame(date), 'day') : [];
}

export const enumerateDaysBetweenDates = function(from, to) {
  if (!from || !to)
    return [];

  const dates = [];

  const startDate = moment(from).startOf('day');
  const endDate = moment(to).startOf('day');

  while(startDate.diff(endDate) <= 0) {
    dates.push(startDate.clone().toDate());
    startDate.add(1, 'days');
  }

  return dates;
};

export const getSubDate = (gap, date) => {
  if (gap && date)
    return date.subtract(gap, 'day').toDate();
  else
    return null;
}

export const getForwardDate = (gap, date) => {
  if (gap && date)
    return date.add(gap, 'day').toDate();
  else
    return null;
}

export const dateStrToMoment = (str) => {
  if (!str)
    return null;
  return moment(str);
}

export const momentToDate = (value) => {
  if (!value)
    return null;

  const date = moment(value).toDate();

  let month = (date.getMonth() + 1);
  let day = date.getDate();
  if (month < 10)
    month = "0" + month;
  if (day < 10)
    day = "0" + day;

  return month + "/" + day + "/" + date.getFullYear();
}

export const dateToString = (date) => {
  let month = (date.getMonth() + 1);
  let day = date.getDate();
  if (month < 10)
    month = "0" + month;
  if (day < 10)
    day = "0" + day;
  return date.getFullYear() + '-' + month + '-' + day;
}