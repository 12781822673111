import React, {useState} from "react";
import RvCollapse from "../rvCollapse";
import styles from "./rvTravelTips.module.sass";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const RvDetailTravelTips = () => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <RvCollapse
      trigger={
        <div className={styles.collapseHeader}>
          <div>
            <h4 className="font-weight-bold">RV Travel Tips</h4>
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.arrowBtn}>
              {isOpened ?
                <BsChevronUp size={18} /> :
                <BsChevronDown size={18} />
              }
            </div>
          </div>
        </div>
      }
      body={
        <div>

        </div>
      }
      setIsOpened={setIsOpened}
    />
  )
}

export default RvDetailTravelTips