import axios from "axios";
import { API_URL } from "../config/config";
import {
  rvsSearchFetch,
  rvsSearchFetchSuccess,
  rvsSearchFetchFailure,
  rvsPopularNearByFetch,
  rvsPopularNearByFetchFailure,
  rvsPopularNearByFetchSuccess,
  rvsItemFetch,
  rvsItemFetchSuccess,
  rvsITemFetchFailure,
  rvsItemBook,
  rvsItemBookSuccess,
  rvsItemBookFailure
} from "../actions/rvAction";

export const searchRVS = (data) => {
  return (dispatch) => {
    dispatch(rvsSearchFetch());
    axios.get(API_URL + 'rvs/findSearch', {params: data})
      .then((response) => {
        dispatch(rvsSearchFetchSuccess(response.data));
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          dispatch(rvsSearchFetchFailure(error.response.data.error));
        }
      })
  }
}

export const searchPopularNearByRVS = (data) => {
  return (dispatch) => {
    dispatch(rvsPopularNearByFetch());
    axios.get(API_URL + 'rvs/findTopNearBy', {params: data})
      .then((response) => {
        if (response?.data?.data)
          dispatch(rvsPopularNearByFetchSuccess(response.data.data));
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          dispatch(rvsPopularNearByFetchFailure(error.response.data.error));
        }
      })
  }
}

export const findRVById = (id) => {
  return (dispatch) => {
    dispatch(rvsItemFetch());
    axios.get(API_URL + 'rvs/findById/' + id)
      .then((response) => {
        if (response?.data?.data)
          dispatch(rvsItemFetchSuccess(response.data.data));
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          dispatch(rvsITemFetchFailure(error.response.data.error));
      })
  }
}

export const book = (data, recaptchaToken) => {
  return (dispatch) => {
    dispatch(rvsItemBook());
    axios.post(API_URL + 'rvs/book', {data, recaptchaToken})
      .then(response => {
        if (response?.data?.success)
          dispatch(rvsItemBookSuccess());
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          dispatch(rvsItemBookFailure(error.response.data.error));
      })
  }
}