import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./listYourRv.module.sass";
import { AiOutlineCheck } from "react-icons/ai";
import RvHeader from "../../components/rvHeader";
import RvFooter from "../../components/rvFooter";
import {GOOGLE_RECAPTCHA_KEY} from "../../config/config";
import {createTicket} from "../../services/ticketService";
import {loadScriptByURL} from "../../util/recaptcha";

const pageTitle = "Rentzly - List Your Rv";

const benefits = [
  {
    label: "No commission for basic listing ALWAYS",
    description: "Our basic listing will always be free, you can pay $50 if you like your listing to be pushed on social/search platforms and getting higher visibility in our platform"
  },
  {
    label: "Earn money",
    description: "Earn extra cash to help pay off your rig or fund your next adventure"
  },
  {
    label: "Join a passionate community",
    description: "Our owner community offers advice for new owners"
  },
  {
    label: "Get paid directly",
    description: "Setup your payment account with Stripe and get paid directly to your Stripe account as customer pays (Including security deposit)"
  },
  {
    label: "Verified renters",
    description: "Our comprehensive driver verification ensures your rig is in good hands"
  }
]

const howItWorks = [
  {
    label: "Import your listing from Outdoorsy OR Create fresh",
    description: "If you are already listing in Outdoorsy, we can import your listing including reviews to Rentzly."
  },
  {
    label: "Get commercial insurance",
    description: "If you notice, today Outdoorsy charges $50/night for insurance, which is good if it is 2-3 days rental. But you do not want to pay $500 for a 10 day trip just as insurance. We will work with you to get discounted commercial insurance per month via MBA Insurance (MBA Insurance, Rental Insurance | #1 Rental Insurance Provider | mbainsurance.net )"
  },
  {
    label: "Setup Stripe",
    description: "You will setup Stripe (https://www.stripe.com) account to receive payouts from guests directly. We need the Stripe API key, so when your unit is booked, the 100% of the payout goes to your Stripe directly."
  },
  {
    label: "Accept new bookings & Earn directly",
    description: "As guests start to book, you will be earning payments directly."
  }
]


const ListYourRv = () => {
  const rvFormRef = useRef(null);
  const [email, setEmail] = useState(null);
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState("Send");

  const submit = (e) => {
    e.preventDefault();

    window.grecaptcha.ready(() => {
      setStatus("sending");
      window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, { action: 'submit' }).then(token => {
        createTicket({ type: "listYourRv", email, outdoorsyURL: url, recaptchaToken: token }).then(() => {
          setStatus("Sent");
        }).catch(() => {
          setStatus("Failed");
        })
      });
    });
  }

  const goRvForm = () => {
    if (!rvFormRef?.current)
      return;

    window.scrollTo({
      behavior: "smooth",
      top: rvFormRef.current.offsetTop
    })
  }

  useEffect(() => {
    document.title = pageTitle;
    window.scroll(0, 0);
    loadScriptByURL();
  }, [])

  return (
    <div>
      <RvHeader />
      <div className="container">
        <div className={styles.header}>

        </div>
        <div className={cn("row", styles.own)}>
          <div className={cn("col-sm-12 col-md-6", styles.ownLeft)}>
            <h2 className="font-weight-bold">
              Own an RV? Earn Over $50,000 per year.
            </h2>
            <p className="text-secondary">
              List for free and rent whenever you want, with full renter verification and vehicle insurance options.
            </p>
          </div>
          <form className={cn("col-sm-12 col-md-6", styles.ownRight)} onSubmit={submit}>
            <h5>
              Let’s get started.
            </h5>
            <p>
              If you have your RV already listed in Outdoorsy, simple just enter your Outdoorsy listing page URL and we will import to create your listing.
            </p>
            <input
              type="text"
              className={styles.input}
              placeholder="Type Your Outdoorsy Listing Link"
              value={url || ""}
              onChange={(e) => setUrl(e.target.value)}
            />
            <p className={styles.hint}>If you don't have, you submit your rv <span className={styles.link} onClick={() => {goRvForm()}}>here</span>.</p>
            <input
              type="text"
              className={styles.input}
              placeholder="Type Your Email"
              required
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className={cn("text-white", "text-center", "py-2", "px-5", "d-inline-block", "mt-3", "mb-4", "rounded", styles.btn)}>
              {status}
            </button>
            <p className={styles.description}>
              This site is protected by reCAPTCHA and the Google <span className={styles.highLight}>Privacy Policy</span> and <span className={styles.highLight}>Terms of Service</span> apply.
            </p>
          </form>
        </div>
        <div className={cn("row", styles.benefits)}>
          <div className={cn("col-sm-12 col-md-6 mb-2 mb-md-0", styles.benefitsLeft)}>
            <h2 className={styles.title}>
              Rentzly - Only commission free RV rental marketplace
            </h2>
            <p>
              Rent your RV today and earn up to <b>$50,000 per year!</b>
            </p>
          </div>
          <div className={cn("col-sm-12 col-md-6", styles.benefitsRight)}>
            {benefits.map((benefit, index) => {
              return (
                <div className={styles.benefitsItem} key={index}>
                  <div className={styles.benefitsCheck}>
                    <AiOutlineCheck size="1.4rem" color="#1fa1a1" />
                  </div>
                  <div>
                    <h6>{benefit.label}</h6>
                    <p className={styles.benefitsLabel}>{benefit.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={cn("row mb-5", styles.howItWorks)}>
          <h4 className={cn("text-center", "w-100", "mb-4", styles.head)}>How It Works</h4>
          <div className={styles.list}>
            {howItWorks.map((item, index) => {
              return (
                <div className={styles.item} key={index}>
                  <div className={styles.mark}>
                    {index + 1}
                  </div>
                  <h5>
                    {item.label}
                  </h5>
                  <p>
                    {item.description}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        <div className={cn("row")} ref={rvFormRef}>
          <h2 className="mb-4">Submit Your RV</h2>
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col">
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Type Your Email"
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Type Your Email"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <RvFooter />
    </div>
  )
}

export default ListYourRv