import React, { useState } from "react";
import cn from "classnames";
import styles from "./deliveryPopup.module.sass";
import {CgCheck} from "react-icons/cg";

const ACTIVE_COLOR = "#1fa1a1";
const deliveryData = {
  driveable: {
    id: "delivery-filter-option-driveable",
    title: "Driveable",
    description: "If you plan on driving the RV, have it delivered to a convenient location and take it from there.",
  },
  stationary: {
    id: "delivery-filter-option-stationary",
    title: "Stationary",
    description: "Don’t want to drive it? Have your RV set up at your destination and we’ll discount your insurance.",
  },
}

const DeliveryPopup = ({ className }) => {
  const [activeDriveable, setActiveDriveable] = useState(false);
  const [activeStationary, setActiveStationary] = useState(false);

  return (
    <div className={cn(className, styles.main)}>
      <div className={styles.content}>
        <div className={styles.body}>
          <div className={styles.list}>
            <div className={styles.items}>
              <input
                className={styles.input}
                type="checkbox"
                id={deliveryData.driveable.id}
                onChange={(e) => {
                  setActiveDriveable(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeDriveable ? styles.active : "")}
                htmlFor={deliveryData.driveable.id}
              >
                <div className={styles.header}>
                  <span className={styles.title}>Driveable</span>
                  {activeDriveable ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.description}>{ deliveryData.driveable.description }</p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={deliveryData.stationary.id}
                onChange={(e) => {
                  setActiveStationary(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeStationary ? styles.active : "")}
                htmlFor={deliveryData.stationary.id}
              >
                <div className={styles.header}>
                  <span className={styles.title}>{deliveryData.stationary.title}</span>
                  {activeStationary ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.description}>{ deliveryData.stationary.description }</p>
              </label>
            </div>
            <div className={styles.emailAddress}>
              <h4>Enter delivery address</h4>
              <input type="email" className={styles.input} />
            </div>
          </div>
          <hr className={styles.border} />
        </div>
        <div className={styles.footer}>
          <button className={styles.btn}>
            No results
          </button>
          <span
            className={styles.txt}
            onClick={() => {
              setActiveDriveable(false);
              setActiveStationary(false);
            }}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default DeliveryPopup

