import axios from "axios";

const url = "https://api.www.s81c.com/webmaster/dbip/";
const params = { callback: "_dl.fn.userIpData.callback" };

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    axios.get(url, {params: params})
      .then((response) => {
        if (response?.data) {
          try {
            let resultStr = response.data;
            if (resultStr.includes("_dl.fn.userIpData.callback"))
              resultStr = resultStr.replace("_dl.fn.userIpData.callback", "");
            if (resultStr.includes("("))
              resultStr = resultStr.replace("(", "");
            if (resultStr.includes(")"))
              resultStr = resultStr.replace(")", "");
            if (resultStr.includes(";"))
              resultStr = resultStr.replace(";", "");

            let resultJson = JSON.parse(resultStr);
            if (resultJson?.latitude && resultJson?.longitude) {
              resolve({ latitude: resultJson.latitude, longitude: resultJson.longitude, country: resultJson.country, city: resultJson.city, state: resultJson.state })
            } else {
              reject("Empty Data");
            }
          } catch (e) {
            reject(e.message);
          }
        } else {
          reject("Empty Data");
        }
      })
      .catch((error) => {
        reject(error);
      })
  })
}