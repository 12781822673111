import React, { useState } from "react";
import cn from "classnames";
import styles from "./drivablePopup.module.sass";
import { AiOutlineCar } from "react-icons/ai";
import { CgCheck } from "react-icons/cg";

const ICON_SIZE = 28;
const ACTIVE_COLOR = "#1fa1a1";
const drivableData = {
  classC: {
    id: "drivable-filter-option-c",
    title: "Class C",
    description: "Great for first timers and families who need more space. Drives like a truck.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  classA: {
    id: "drivable-filter-option-a",
    title: "Class A",
    description: "A big RV with the amenities of a home. Drives like a bus, great for delivery.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  campervan: {
    id: "drivable-filter-option-campervan",
    title: "Campervan",
    description: "The smallest fully enclosed RV. Drives like a van and loves posing for selfies.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  classB: {
    id: "drivable-filter-option-b",
    title: "Class B",
    description: "A little bigger than a campervan but just as photogenic.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  truckCamper: {
    id: "drivable-filter-option-truckcamper",
    title: "Truck Camper",
    description: "Drives like a truck and makes roughing it significantly less rough.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  other: {
    id: "drivable-filter-option-other",
    title: "Other",
    description: "All other types of drivable RVs. (We’re just as curious as you are.)",
    icon: <AiOutlineCar size={ICON_SIZE} />
  }
};

const DrivablePopup = ({ className }) => {
  const [activeC, setActiveC] = useState(false);
  const [activeA, setActiveA] = useState(false);
  const [activeCampervan, setActiveCampervan] = useState(false);
  const [activeClassB, setActiveClassB] = useState(false);
  const [activeTruckCamper, setActiveTruckCamper] = useState(false);
  const [activeOther, setActiveOther] = useState(false);

  return (
    <div className={cn(className, styles.main)}>
      <div className={styles.content}>
        <div className={styles.body}>
          <div className={styles.list}>
            <div className={styles.items}>
              <input
                className={styles.input}
                type="checkbox"
                id={ drivableData.classC.id }
                onChange={(e) => {
                  setActiveC(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeC ? styles.active : "")}
                htmlFor={ drivableData.classC.id }
              >
                <div className={styles.header}>
                  { drivableData.classC.icon }
                  {activeC ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ drivableData.classC.title }</p>
                <p className={styles.description}>
                  { drivableData.classC.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ drivableData.classA.id }
                onChange={(e) => {
                  setActiveA(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeA ? styles.active : "")}
                htmlFor={ drivableData.classA.id }
              >
                <div className={styles.header}>
                  { drivableData.classA.icon }
                  {activeA ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ drivableData.classA.title }</p>
                <p className={styles.description}>
                  { drivableData.classA.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ drivableData.campervan.id }
                onChange={(e) => {
                  setActiveCampervan(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeCampervan ? styles.active : "")}
                htmlFor={ drivableData.campervan.id }
              >
                <div className={styles.header}>
                  { drivableData.campervan.icon }
                  {activeCampervan ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ drivableData.campervan.title }</p>
                <p className={styles.description}>
                  { drivableData.campervan.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ drivableData.classB.id }
                onChange={(e) => {
                  setActiveClassB(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeClassB ? styles.active : "")}
                htmlFor={ drivableData.classB.id }
              >
                <div className={styles.header}>
                  { drivableData.classB.icon }
                  {activeClassB ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ drivableData.classB.title }</p>
                <p className={styles.description}>
                  { drivableData.classB.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ drivableData.truckCamper.id }
                onChange={(e) => {
                  setActiveTruckCamper(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeTruckCamper ? styles.active : "")}
                htmlFor={ drivableData.truckCamper.id }
              >
                <div className={styles.header}>
                  { drivableData.truckCamper.icon }
                  {activeTruckCamper ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ drivableData.truckCamper.title }</p>
                <p className={styles.description}>
                  { drivableData.truckCamper.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ drivableData.other.id }
                onChange={(e) => {
                  setActiveOther(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, activeOther ? styles.active : "")}
                htmlFor={ drivableData.other.id }
              >
                <div className={styles.header}>
                  { drivableData.other.icon }
                  {activeOther ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ drivableData.other.title }</p>
                <p className={styles.description}>
                  { drivableData.other.description }
                </p>
              </label>
            </div>
            <div
              className={styles.selectAllWrapper}
              onClick={() => {
                setActiveC(true);
                setActiveA(true);
                setActiveCampervan(true);
                setActiveClassB(true);
                setActiveTruckCamper(true);
                setActiveOther(true);
              }}
            >
              <span className={styles.btn}>
                Select All
              </span>
            </div>
          </div>
          <hr className={styles.border} />
        </div>
        <div className={styles.footer}>
          <button className={styles.btn}>
            No results
          </button>
          <span
            className={styles.txt}
            onClick={() => {
              setActiveC(false);
              setActiveA(false);
              setActiveCampervan(false);
              setActiveClassB(false);
              setActiveTruckCamper(false);
              setActiveOther(false);
            }}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default DrivablePopup