import React, {useEffect, useState} from "react";
import { useParams, useHistory } from "react-router-dom";
import cn from "classnames";
import { bindActionCreators } from "redux";
import moment from "moment";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiFillStar } from "react-icons/ai";
import { BsFillStarFill } from "react-icons/bs";
import { GiPathDistance } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import { FiZap } from "react-icons/fi";
import { BiChevronRight } from "react-icons/bi";
import { GiElectric } from "react-icons/gi";
import RvHeader from "../../components/rvHeader";
import RvFooter from "../../components/rvFooter";
import RvDetailImages from "../../components/rvDetailImages";
import RvDetailSpecs from "../../components/rvDetailSpecs";
import RvDetailAmenities from "../../components/rvDetailAmenities";
import RvDetailRulesPolicies from "../../components/rvDetailRulesPolicies";
import RvDetailTravelTips from "../../components/rvDetailTravelTips";
import RvDetailMap from "../../components/rvDetailMap/rvDetailMap";
import RvCard from "../../components/rvCard";
import RvEmbedDateRangePicker from "../../components/rvEmbedDateRangePicker";
import RvDateRangePicker from "../../components/rvDateRangePicker";
import styles from "./detail.module.sass";
import "./imageSlider.sass";
import {getCurrentLocation} from "../../services/locationService";
import * as rvService from "../../services/rvService";
import {formatNumber} from "../../util/number";
import {enumerateDaysBetweenDates} from "../../util/date";
import { useQuery } from "../../util/url";
import RvModal from "../../components/rvModal";

const reviewsList = [
  {avatarName: "O", name: "Orane H", rating: 5, date: "October 2021", content: "Awesome experience. Would definitely recommend and looking forward to renting the van again ! Van is clean and outfitted with everything you need."},
  {avatarName: "M", name: "Mireille E", rating: 5, date: "October 2021", content: "Our experience was 5 star from start to finish! This was our first experience with van life and we had such an amazing time. Kevin was so helpful and answered any question we had even before we booked with him. He was very responsive and had everything set up in the van so it was extremely easy to use, even for."},
];

const initSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  initialSlide: 0
};


const Detail = ({ popularRVS, rv, actions }) => {
  const query = useQuery();
  const history = useHistory();
  const { id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addOns, setAddOns] = useState(null);
  const [totalDays, setTotalDays] = useState(null);
  const [gapDays, setGapDays] = useState(null);
  const [price, setPrice] = useState(null);
  const [daysPrice, setDaysPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [addOnsData, setAddOnsData] = useState(null);
  const [visibleImagesModal, setVisibleImagesModal] = useState(false);
  const [slideSetting, setSlideSetting] = useState(initSettings);

  const imageClicked = (index) => {
    setSlideSetting({...slideSetting, initialSlide: index});
    setVisibleImagesModal(true);
  }

  const dateRangeChanged = (data) => {
    if (data) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
  }

  const addonsChanged = (index, value) => {
    const newAddons = [...addOns];
    newAddons[index].checked = value;
    setAddOns(newAddons);
  }

  const requestBookClicked = () => {
    sessionStorage.setItem("book", JSON.stringify({rv: rv, state: {startDate, endDate, addOnsData}}));
    history.push({pathname: "/book"});
  }

  useEffect(() => {
    setTotalPrice(null);
    if (price && totalDays) {
      setDaysPrice((price / 100) * totalDays);
      if (addOnsData?.price) {
        setTotalPrice((price / 100) * totalDays + addOnsData.price / 100);
      } else {
        setTotalPrice((price / 100) * totalDays);
      }
    }
  }, [price, totalDays, addOnsData])

  useEffect(() => {
    setTotalDays(null);
    setGapDays(null);
    setPrice(null);
    setDaysPrice(null);
    setTotalPrice(null);

    if (startDate && endDate && rv) {
      let newTotalDays = null;
      let newPrice = rv?.price_per_day;
      const enumDays = enumerateDaysBetweenDates(startDate, endDate);
      if (rv.activeOptions) {
        if (rv.activeOptions.use_day_pricing) {
          newTotalDays = enumDays.length;
          if (rv.activeOptions.minimum_days && rv.activeOptions.minimum_days > newTotalDays) {
            newTotalDays = rv.activeOptions.minimum_days;
            setGapDays(rv.activeOptions.minimum_days - newTotalDays);
          }
        } else {
          newTotalDays = enumDays.length - 1;
          if (rv.activeOptions.minimum_days && rv.activeOptions.minimum_days > newTotalDays) {
            setGapDays(rv.activeOptions.minimum_days - newTotalDays);
            newTotalDays = rv.activeOptions.minimum_days;
          }
        }
        if (rv.activeOptions.day_price)
          newPrice = rv.activeOptions.day_price;
      } else {
        newTotalDays = enumDays.length;
      }
      setPrice(newPrice);
      setTotalDays(newTotalDays);
    }
  }, [startDate, endDate, rv])

  useEffect(() => {
    setAddOnsData(null);
    let addOnsCount = null;
    let addOnsPrice = null;

    addOns?.map(item => {
      if (item.checked) {
        if (addOnsCount) {
          addOnsCount++;
        } else {
          addOnsCount = 1;
        }

        if (addOnsPrice)
          addOnsPrice += item.price;
        else
          addOnsPrice = item.price;
      }
    });

    if (addOnsCount && addOnsPrice)
      setAddOnsData({ count: addOnsCount, price: addOnsPrice });
  }, [addOns])

  useEffect(() => {
    if (rv?.filtered_name)
      document.title = rv.filtered_name;

    let newAddons = [];
    newAddons = rv?.items?.map((item) => {
      return {...item, ...{checked: false}};
    });
    setAddOns(newAddons);
  }, [rv])

  useEffect(() => {
    if (id)
      actions.findRVById(id);
  }, [id])

  useEffect(() => {
    window.scroll(0, 0);

    const func = async () => {
      await getCurrentLocation().then(response => {
        if (response?.latitude && response.longitude) {
          actions.searchPopularNearByRVS({ lat: response.latitude, lng: response.longitude });
        }
      })
    }

    func();

    if (query) {
      const start = !query.get("startDate") || query.get("startDate") === "undefined" || query.get("startDate") === "null" ? null : moment(query.get("startDate"));
      const end = !query.get("endDate") || query.get("endDate") === "undefined" || query.get("endDate") === "null" ? null : moment(query.get("endDate"));
      setStartDate(start);
      setEndDate(end);
    }
  }, [])

  return (
    <div className={styles.main}>
      <RvHeader />
      <RvDetailImages data={rv?.images} onClick={imageClicked} />
      <div className={cn("container-fluid", styles.detailWrap)}>
        <div className="row">
          <div className="col-12 col-lg-8">
            <h1 className="font-weight-bold">{rv?.filtered_name}</h1>
            <div className={styles.headerInfoWrap}>
              {(rv?.score && rv?.reviews_num) ?
                <div className={cn(styles.headerLeft, styles.mainColor)}>
                  <span className="font-weight-bold d-flex align-items-center mr-1">
                    <BsFillStarFill />
                    {rv.score}
                  </span>
                  <span>
                    ({rv.reviews_num})
                  </span>
                </div> : ""
              }
              {(rv?.display_vehicle_type && rv?.location?.city && rv?.location?.state) &&
                <div className={styles.headerLeft}>
                  <span>
                    {rv.display_vehicle_type}
                    <span>&nbsp;•&nbsp;</span>
                  </span>
                  <span>
                    {rv.location.city}, {rv.location.state}
                  </span>
                </div>
              }
              <div>
                {rv?.seatbelts ?
                  <span>
                    {rv.seatbelts} seatbelts
                    {(rv?.sleeps || rv?.vehicle_length || rv?.vehicle_year) ?
                      <span>&nbsp;•&nbsp;</span> : ""
                    }
                  </span> : ""
                }
                {rv?.sleeps ?
                  <span>
                    Sleeps {rv.sleeps}
                    {(rv?.vehicle_length || rv?.vehicle_year) ?
                      <span>&nbsp;•&nbsp;</span> : ""
                    }
                  </span> : ""
                }
                {rv?.vehicle_length ?
                  <span>
                    {rv.vehicle_length} ft
                    {rv?.vehicle_year ?
                      <span>&nbsp;•&nbsp;</span> : ""
                    }
                  </span> : ""
                }
                {rv?.vehicle_year ?
                  <span>
                    Year {rv.vehicle_year}
                  </span> : ""
                }
              </div>
            </div>
            <div className={styles.buttonInfoWrap}>
              <span className={cn(styles.btn, "d-flex", "align-items-center")}>
                100 miles/day free
                <GiPathDistance className="ml-1" />
              </span>
              <span className={cn(styles.btn, "d-flex", "align-items-center")}>
                GENERATOR INCLUDED
                <AiOutlineCar className="ml-1" />
              </span>
            </div>
            <div className={cn("container-fluid", styles.questionWrap)}>
              <div className="row mb-5">
                <div className={cn("col-12 col-md-8", "p-0")}>
                  <h4 className={cn("font-weight-bold", "mb-4")}>Meet your host, RVRentsNow</h4>
                  <p className="mb-5">
                    We love RV-ing so much that we are starting to manage not only our RV but also our friend's RV's as well and In doing so formed RVRENTSnow. I run a short term furnished rental company by day, and we are ranked 4.8 out of 5
                  </p>
                  <button
                    className={cn(styles.questionBtn)}
                    onClick={() => {
                      if (window?.fcWidget) {
                        window.fcWidget.open();
                        window.fcWidget.show();
                      }
                    }}
                  >
                    Ask owner a question
                  </button>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end mt-5 mt-md-0">
                  <div className={styles.imageWrap}>
                    <div className={styles.imageContainer}>
                      <img className={styles.image} src="https://res.cloudinary.com/outdoorsy/image/upload/c_limit,w_2880,h_2160/t_odw,a_exif,q_auto,f_auto,w_auto,c_fill/v1627095999/p/users/217998/images/hdwzb9sm3nkzgoysjsrj.png" alt="question" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 pl-0">
                  <div className={styles.block}>
                    <div className={styles.head}>
                      <FiZap className="mr-3" size={25} />
                      <div>
                        <h6 className="mb-1">Enhanced Clean</h6>
                        <p className="m-0">Rentzly guidelines</p>
                      </div>
                    </div>
                    <p className="m-0">
                      Your safety and health is key. This host has committed to a thorough cleaning regimen informed by CDC guidelines.
                    </p>
                  </div>
                  <p className={styles.link}>
                    Learn how this vehicle is cleaned
                    <BiChevronRight />
                  </p>
                </div>
                <div className="col-12 col-md-6 pl-0 pl-md-1 mt-4 mt-md-0">
                  <div className={styles.block}>
                    <div className={styles.head}>
                      <div className={cn(styles.headAvatar, "mr-3")}>
                        K
                      </div>
                      <div>
                        <h6 className="mb-1">Keith C.</h6>
                        <p className="m-0">
                          <span className={styles.headMark}>
                            <BsFillStarFill className="mr-1" size={12} />
                            5
                          </span>
                          <span>
                            <span>&nbsp;•&nbsp;</span>
                            October 2021
                          </span>
                        </p>
                      </div>
                    </div>
                    <p className="m-0">
                      Wow lots of fun, Great RV , Steve is top notch and we will be telling our friends!
                    </p>
                  </div>
                  <p className={styles.link}>
                    View all 2 reviews
                    <BiChevronRight />
                  </p>
                </div>
              </div>
            </div>
            <div className={cn("mb-5", styles.enhanceWrap)}>
              <RvDetailSpecs rv={rv} />
              <RvDetailAmenities rv={rv} />
              <RvDetailRulesPolicies rv={rv} />
              <RvDetailTravelTips />
              <div className={styles.enhanceBlock}>
                <h4 className="font-weight-bold">Enhance your trip</h4>
                {addOns?.map((item, index) => {
                  if (item?.price > 0)
                    return (
                      <div className={cn(styles.enhanceBottom, "mb-3")} key={index}>
                        <div>
                          <div>
                            <label className={styles.checkContainer}>
                            <span className={styles.labelTitle}>
                              {item.name}
                            </span>
                              <input
                                className={styles.checkInput}
                                type="checkbox"
                                value={item.checked}
                                checked={item.checked}
                                onChange={(e) => addonsChanged(index, e.target.checked)}
                              />
                              <span className={styles.checkMark}></span>
                            </label>
                          </div>
                          {item.description?.length > 0 &&
                            <p className={styles.description}>{item.description}</p>
                          }
                        </div>
                        <div>
                          <h6 className="m-0">${formatNumber(item.price / 100)}</h6>
                          <small>/each</small>
                        </div>
                      </div>
                    )
                })}
              </div>
            </div>
            <div className={cn("mb-5", styles.availabilityWrap)}>
              <h4 className="font-weight-bold mb-1">Availability</h4>
              <p>This rental has a 2 day minimum.</p>
              <RvEmbedDateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                bookedDatesData={rv?.bookedDates}
              />
            </div>
            {rv?.score ?
              <div className={cn("mb-5", "container-fluid", styles.reviewsWrap)}>
                <div className="row">
                  <div className="col-12 col-md-4 p-0 mb-5 mb-md-0">
                    <h3 className="mb-4 font-weight-bold">Reviews <span className={styles.colorLabel}>(<AiFillStar />{rv.score})</span></h3>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col p-0 mr-2">
                          <h6 className="font-weight-bold mb-3">About this RV</h6>
                          <div className="d-flex justify-content-between mb-3">
                            <small>Cleanliness</small>
                            {rv.averageRating?.cleanliness_score ?
                              <small className={styles.colorLabel}><AiFillStar />{rv.averageRating.cleanliness_score}</small>
                              : ""
                            }
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                            <small>Maintenance</small>
                            {rv.averageRating?.mechanical_score ?
                              <small className={styles.colorLabel}><AiFillStar />{rv.averageRating.mechanical_score}</small>
                              : ""
                            }
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                            <small>Value</small>
                            {rv.averageRating?.value_score ?
                              <small className={styles.colorLabel}><AiFillStar />{rv.averageRating.value_score}</small>
                              : ""
                            }
                          </div>
                          <div className="d-flex justify-content-between">
                            <small>Listing Accuracy</small>
                            {rv.averageRating?.listing_score ?
                              <small className={styles.colorLabel}><AiFillStar />{rv.averageRating.listing_score}</small>
                              : ""
                            }
                          </div>
                        </div>
                        <div className="col p-0 ml-2">
                          <h6 className="font-weight-bold mb-3">About this host</h6>
                          <div className="d-flex justify-content-between">
                            <small>Communication</small>
                            {rv.averageRating?.communication_score ?
                              <small className={styles.colorLabel}><AiFillStar />{rv.averageRating.communication_score}</small>
                              : ""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-8 pl-0 pl-md-5 pr-0">
                    {reviewsList.map((item, index) => {
                      return (
                        <div className="mb-5" key={index}>
                          <div className="mb-3">
                            <div className="d-flex align-items-center">
                              <div className={styles.avatar}>
                                {item.avatarName}
                              </div>
                              <div>
                                <h6 className="font-weight-bold mb-1">{item.name}</h6>
                                <small className="m-0">
                                <span className={styles.colorLabel}>
                                  <AiFillStar />{item.rating}
                                </span>
                                  <span>&nbsp;•&nbsp;</span>
                                  {item.date}
                                </small>
                              </div>
                            </div>
                          </div>
                          <p>
                            {item.content}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div> : ""
            }
            <div className={cn("mb-5", styles.locationWrap)}>
              <div className={styles.location}>
                <RvDetailMap
                  loadingElement={<div style={{width: "100%", height: "100%"}}></div>}
                  containerElement={<div style={{width: "100%", height: "100%"}}></div>}
                  mapElement={<div style={{width: "100%", height: "100%"}}></div>}
                  location={{lat: rv?.location?.lat, lng: rv?.location?.lng}}
                />
              </div>
            </div>
            <div className="mb-5 container-fluid">
              <div className="row">
                <h3 className="font-weight-bold">Similar Rentals</h3>
              </div>
              <div className="row">
                {popularRVS?.map((item, index) => {
                  return (
                    <div className="col-12 col-md-6 col-xl-3 justify-content-center mb-5 mb-md-0 d-flex p-1" key={index}>
                      <RvCard
                        data={item}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {rv?.price_per_day || rv?.activeOptions?.day_price ?
            <div className="col-12 col-lg-4 d-flex justify-content-end">
              <div className={styles.checkWrap}>
                <div className="container-fluid">
                  <div className="row mb-4">
                    <div className="d-flex align-items-end">
                      <GiElectric size={25} color="#23B5B5" />
                      <h4 className="font-weight-bold d-inline m-0">${rv?.activeOptions?.day_price / 100 || rv.price_per_day / 100}</h4> /{rv.activeOptions?.use_day_pricing ? "day" : "night"}
                    </div>
                  </div>
                  {gapDays ?
                    <div className="row mb-3">
                      <p className={cn("m-0", styles.colorLabel)}>
                        <small>This rental has {rv.activeOptions.minimum_days} nights minimum. You will be charged for {gapDays} additional nights if you proceed</small>
                      </p>
                    </div> : ""
                  }
                  <div className="row mb-4">
                    <RvDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={dateRangeChanged}
                      bookedDatesData={rv?.bookedDates}
                    />
                  </div>
                  {totalPrice && price && daysPrice && totalDays ?
                    <div>
                      <div className={cn("row d-flex justify-content-between align-items-center pb-3", styles.borderBottom)}>
                        <span>Pick up</span>
                        <span>{`${rv?.location?.city || ""}, ${rv?.location?.state || ""}`}</span>
                      </div>
                      <div className="row d-flex justify-content-between align-items-center pt-3 mb-2">
                        <span>${formatNumber(price / 100)} * {totalDays} {rv.activeOptions?.use_day_pricing ? "days" : "nights"}</span>
                        <span>${formatNumber(daysPrice)}</span>
                      </div>
                      <div className={cn("row d-flex justify-content-between align-items-center pb-3 mb-3", styles.borderBottom)}>
                        <span className={styles.colorLabel}>Add-ons {addOnsData?.count ? `(${addOnsData?.count})` : ""}</span>
                        <span>{addOnsData?.price ? `$${formatNumber(addOnsData.price / 100)}` : "-"}</span>
                      </div>
                      <div className="row d-flex justify-content-between align-items-center mb-4">
                        <span>Total</span>
                        <span>${formatNumber(totalPrice)}</span>
                      </div>
                    </div> : ""
                  }
                  {totalPrice ?
                    <div className="row mt-4">
                      <button className={styles.checkBtn} onClick={requestBookClicked}>
                        Request Book
                      </button>
                    </div> :
                    <div className="row mt-4">
                      <button className={styles.checkBtn}>
                        Check Availability
                      </button>
                    </div>
                  }

                </div>
              </div>
            </div> : ""
          }
        </div>
      </div>
      <RvModal visible={visibleImagesModal} onClose={() => setVisibleImagesModal(false)} classes={styles.modal}>
        <Slider {...slideSetting}>
          {rv?.images.map((item, index) => {
            return (
              <div className={styles.imageSlideItem} key={index}>
                <img className={styles.image} src={item.url} alt="rv" />
              </div>
            )
          })}
        </Slider>
      </RvModal>
      <RvFooter />
    </div>
  )
}


const mapStateToProps = (state) => ({
  popularRVS: state.rvs.popularNearByData,
  rv: state.rvs.item
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, rvService), dispatch)
})


export default connect(mapStateToProps, mapDispatchToProps)(Detail)