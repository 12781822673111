import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./about.module.sass";
import RvHeader from "../../components/rvHeader";
import RvFooter from "../../components/rvFooter";

const pageTitle = "Rentzly - About Us";

const About = () => {

  useEffect(() => {
    document.title = pageTitle;
    window.scroll(0, 0);
  }, [])

  return (
    <div className={cn("container", styles.main)}>
      <RvHeader />
      <div className={styles.section}>
        <h1 className="font-weight-bold mb-4">WE SAW A PROBLEM</h1>
        <p className="m-0">
          Current RV Rental platforms charge 20% to owner, 10% renter, then fees this & that.Sorry, but running an online marketplace doesn't have to be this costly. That's where we are born. We are launching ZERO DOLLAR commission platform for basic listing of RV in our site. Yes, absolutely zero dollar. Browse our inventory, contact us to list your RV or rent an RV from us. Whether you are an owner or a renter, you pay $0. We also have an app on IPhone and Android for owners to manage the renters experience. We also have AI driven personalized marketing technique (both online and offline) for owners to reach out to potential renters. Join hundreds of owners who made smart decision of listing RVs on multiple platforms including Rentzly by diversifying and lowering their risk of being kicked out on one platform.
        </p>
      </div>
      <div className={styles.section}>
        <h1 className="font-weight-bold mb-4">HOW DOES IT WORK?</h1>
        <div className="mb-4">
          <h4 className="font-weight-bold mb-3">Insurance</h4>
          <p className="m-0">
            You buy commercial insurance with MBA Insurance (We have discounted rate with them).  While it will be a monthly fee, if you are renting at least 5+ days a month, then the commercial insurance will be less than $50/night insurance Outdoorsy/RVShare charges. Any renter damage, you will work with MBA claims team.
          </p>
        </div>
        <div>
          <h4 className="font-weight-bold mb-3">Stripe</h4>
          <p className="m-0">
            You will setup an account with Stripe and give us the API key. When customer pays, it will be automatically disbursed to your account including the security deposit. When renter returns, you will refund the security deposit.
          </p>
        </div>
      </div>
      <div className={styles.section}>
        <h1 className="font-weight-bold mb-4">HOW WILL WE MAKE MONEY?</h1>
        <div className="mb-4">
          <h4 className="font-weight-bold mb-3">Advertising</h4>
          <p className="m-0">
            Google does not charge to search, but still they make money, isn't it? Same model. Basic listing , booking is free. But if you want to advertise your listing highlighter on top, then you can sponsor your RV listing.
          </p>
        </div>
      </div>
      <RvFooter />
    </div>
  )
}

export default About