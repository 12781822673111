import React from "react";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import styles from "./rvMapInfoBox.module.sass";

const MAX_WIDTH = 280;
const PIXEL_OFFSET = { width: 0, height: -100 };
const ENABLE_EVENT_PROPAGATION = false;

const RvMapInfoBox = ({ location, data }) => {
  return (
    <InfoBox
      defaultPosition={ new window.google.maps.LatLng(location.lat, location.lng) }
      position={ new window.google.maps.LatLng(location.lat, location.lng)  }
      options={{
        pixelOffset: new window.google.maps.Size(PIXEL_OFFSET.width, PIXEL_OFFSET.height),
        maxWidth: MAX_WIDTH,
        closeBoxURL: ``,
        enableEventPropagation: ENABLE_EVENT_PROPAGATION
      }}
    >
      <div className={styles.main} >
        <div className={styles.imgWrapper}>
          <img className={styles.image} src={data.primary_image_url} alt="rv" />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <p className={styles.name}>{data.name}</p>
            <p className={styles.values}>
              <span>{data.vehicle_year} {data.display_vehicle_type}</span>
              <span>&nbsp;•&nbsp;</span>
              <span>20 ft</span>
            </p>
            <p className={styles.values}>
              Sleeps {data.sleeps || "0"}, Seats {data.seatbelts || "0"}
            </p>
          </div>
          <div className={styles.footer}>
            <p className={styles.values}>
              <span className={styles.price}>${data.price_per_day / 100}</span>
              &nbsp;
              <span>/day</span>
            </p>
          </div>
        </div>
      </div>
    </InfoBox>
  )
}

export default RvMapInfoBox