import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {AiFillStar} from "react-icons/ai";
import {FcElectricity} from "react-icons/fc";
import styles from "./rvCard.module.sass";
import "./rvCarousel.sass";
import ImageRenderer from "../imageRenderer";

const THUMB_IMG_URL = "https://i.imgur.com/OdiNNyym.jpg";
const IMG_HEIGHT = 90;
const IMG_WIDTH = 100;

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const RvCard = ({ data, onClick }) => {

  return (
    <div className={styles.card} onClick={onClick}>
      {data &&
        <article>
          <div className={styles.cardHeader}>
            <h2>{data.filtered_name}</h2>
            <div className={styles.images}>
              <Slider {...settings}>
                <ImageRenderer
                  url={data.primary_image_url}
                  thumb={THUMB_IMG_URL}
                  height={IMG_HEIGHT}
                  width={IMG_WIDTH}
                />
                <ImageRenderer
                  url={data.primary_image_url}
                  thumb={THUMB_IMG_URL}
                  height={IMG_HEIGHT}
                  width={IMG_WIDTH}
                />
                <ImageRenderer
                  url={data.primary_image_url}
                  thumb={THUMB_IMG_URL}
                  height={IMG_HEIGHT}
                  width={IMG_WIDTH}
                />
              </Slider>
              {/*<Carousel autoPlay={true} showThumbs={false}>*/}
              {/*  <ImageRenderer*/}
              {/*    url={data.primary_image_url}*/}
              {/*    thumb={THUMB_IMG_URL}*/}
              {/*    height={IMG_HEIGHT}*/}
              {/*    width={IMG_WIDTH}*/}
              {/*  />*/}
              {/*  <ImageRenderer*/}
              {/*    url={data.primary_image_url}*/}
              {/*    thumb={THUMB_IMG_URL}*/}
              {/*    height={IMG_HEIGHT}*/}
              {/*    width={IMG_WIDTH}*/}
              {/*  />*/}
              {/*  <ImageRenderer*/}
              {/*    url={data.primary_image_url}*/}
              {/*    thumb={THUMB_IMG_URL}*/}
              {/*    height={IMG_HEIGHT}*/}
              {/*    width={IMG_WIDTH}*/}
              {/*  />*/}
              {/*</Carousel>*/}
            </div>
          </div>
          <div className={styles.cardInfo}>
            <span>
              {data.vehicle_year} {data.display_vehicle_type}
              <span>&nbsp;•&nbsp;</span>
            </span>
            <span>
              {data.vehicle_length} ft
              <span>&nbsp;•&nbsp;</span>
            </span>
            <span>
              {data.city}, {data.state}
            </span>
            <div className={styles.cardAvailable}>
              {data.score ?
                <span className={styles.cardRating}>
                  <AiFillStar/>
                  {data.score}
                </span> : ""
              }
              {data.reviews_num ?
                <span>
                  ({data.reviews_num})
                  <span>&nbsp;•&nbsp;</span>
                </span> : ""
              }
              <span>
                Sleeps {data.sleeps || "0"}, Seats {data.seatbelts || "0"}
              </span>
            </div>
          </div>
          {data.price_per_day &&
            <div className={styles.cardPrice}>
              <span>
                <FcElectricity/>
              </span>
              <span className={styles.cardValue}>
                ${data.price_per_day / 100}
              </span>
              &nbsp;
              <span className={styles.cardUnit}>/day</span>
            </div>
          }
        </article>
      }
    </div>
  )
}

export default RvCard