import React, {useEffect, useState} from "react";
import GooglePlacesAutocompleteProps from "react-google-places-autocomplete";
import cn from "classnames";
import styles from "./rvLocationInput.module.sass";
import locationImg from "../../assets/img/location.svg";
import {getGeoCodeByPlaceId, getLatLngByGeoCode} from "../../util/location";

const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%"
  }),
  control: (provided, state) => ({
    ...provided,
    display: "flex",
    height: "100%",
    backgroundColor: "#fff",
    paddingLeft: "25px",
    borderRadius: "6px",
    border: "none",
    margin: "0",
    boxShadow: state.isFocused ? "0 0 0 0.2rem #007bff40" : "0px 0px 4px #00000026",
    cursor: "text"
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#495057",
    textAlign: "left",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : state.isFocused ? "#fff" : "#495057",
    background: state.isSelected ? "#1fa1a1" : state.isFocused ? "#1fa1a1" : "#fff",
    textAlign: "left",
    cursor: "pointer"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#495057",
    textAlign: "left"
  }),
  indicatorSeparator: () => null,
  dropdownIndicator: defaultStyles => ({
    ...defaultStyles,
    "& svg": { display: "none" }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#A6A6A6"
  })
}

const RvLocationInput = ({ location, onChange }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const func = async () => {
      if (value?.value?.place_id) {
        const geoCodes = await getGeoCodeByPlaceId(value.value.place_id);
        if (geoCodes?.length < 1)
          return;
        const latLng = await getLatLngByGeoCode(geoCodes[0]);
        if (latLng)
          onChange({...latLng, label: value.label});
      }
    }
    func();
  }, [value])

  useEffect(() => {
    if (!location)
      setValue(null);
  }, [location])

  return (
    <div className={cn("mb-0", styles.locationWrap)}>
      <img className={styles.locationImg} src={locationImg} alt="location" />
      {location?.address &&
        <GooglePlacesAutocompleteProps
          className={styles.locationInput}
          selectProps={{
            defaultInputValue: location.address,
            value,
            styles: selectStyles,
            placeholder: "Try San Antonio, Las Vegas, New york, Jacksonville...",
            listViewDisplayed: undefined,
            noOptionsMessage: () => null,
            onChange: setValue
          }}
        />
      }
      {!location?.address &&
        <GooglePlacesAutocompleteProps
          className={styles.locationInput}
          selectProps={{
            value,
            styles: selectStyles,
            placeholder: "Try San Antonio, Las Vegas, New york, Jacksonville...",
            listViewDisplayed: undefined,
            noOptionsMessage: () => null,
            onChange: setValue
          }}
        />
      }
    </div>
  )
}

export default RvLocationInput