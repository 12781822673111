import React from "react";
import ReactLoading from "react-loading";
import styles from "./rvLoadCard.module.sass"

const RvLoadCard = () => {
  return (
    <div className={styles.card}>
      <ReactLoading type="spokes" />
    </div>
  )
}

export default RvLoadCard