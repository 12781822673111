import React, {useEffect, useState} from "react";
import cn from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import styles from "./book.module.sass";
import RvHeader from "../../components/rvHeader";
import RvFooter from "../../components/rvFooter";
import RvDateRangePicker from "../../components/rvDateRangePicker";
import RvModal from "../../components/rvModal";
import * as rvService from "../../services/rvService";
import { GOOGLE_RECAPTCHA_KEY } from "../../config/config";
import { loadScriptByURL } from "../../util/recaptcha";
import { enumerateDaysBetweenDates, dateStrToMoment, momentToDate}  from "../../util/date";
import { formatNumber } from "../../util/number";

const pageTitle = "Rentzly - Book";

const Book = ({ loadFlag, success, actions }) => {
  const history = useHistory();
  const [rv, setRV] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addOnsData, setAddOnsData] = useState(null);
  const [totalDays, setTotalDays] = useState(null);
  const [price, setPrice] = useState(null);
  const [daysPrice, setDaysPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);

  const dateRangeChanged = (data) => {
    if (data) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
  }

  const firstNameChanged = (e) => {
    setFirstName(e.target.value);
  }

  const lastNameChanged = (e) => {
    setLastName(e.target.value);
  }

  const emailChanged = (e) => {
    setEmail(e.target.value);
  }

  const phoneNumberChanged = (e) => {
    setPhoneNumber(e.target.value);
  }

  const descriptionChanged = (e) => {
    setDescription(e.target.value);
  }

  const submit = (e) => {
    e.preventDefault();

    if (!startDate || !endDate)
      return;

    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      description,
      startDate: momentToDate(startDate),
      endDate: momentToDate(endDate),
      totalPrice,
      rv: {
        Listing_ID: rv.Listing_ID,
        filtered_name: rv.filtered_name,
        display_vehicle_type: rv.display_vehicle_type,
        sleeps: rv.sleeps,
        seatbelts: rv.seatbelts,
        city: rv.location?.city,
        state: rv.location?.state,
      }
    };

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, { action: 'submit' }).then(token => {
        actions.book(data, token);
      });
    });
  }

  useEffect(() => {
    if (success && success === true)
      setVisibleModal(true);
  }, [success])

  useEffect(() => {
    setTotalPrice(null);

    if (price && totalDays) {
      setDaysPrice((price / 100) * totalDays);
      if (addOnsData?.price) {
        setTotalPrice((price / 100) * totalDays + addOnsData.price / 100);
      } else {
        setTotalPrice((price / 100) * totalDays);
      }
    }
  }, [price, totalDays, addOnsData])

  useEffect(() => {
    if (!rv || !startDate || !endDate)
      return;

    let newTotalDays = null;
    let newPrice = rv?.price_per_day;
    const enumDays = enumerateDaysBetweenDates(startDate, endDate);

    if (rv.activeOptions) {
      if (rv.activeOptions.use_day_pricing) {
        newTotalDays = enumDays.length;
        if (rv.activeOptions.minimum_days && rv.activeOptions.minimum_days > newTotalDays) {
          newTotalDays = rv.activeOptions.minimum_days;
        }
      } else {
        newTotalDays = enumDays.length - 1;
        if (rv.activeOptions.minimum_days && rv.activeOptions.minimum_days > newTotalDays) {
          newTotalDays = rv.activeOptions.minimum_days;
        }
      }
      if (rv.activeOptions.day_price)
        newPrice = rv.activeOptions.day_price;
    } else {
      newTotalDays = enumDays.length;
    }

    setPrice(newPrice);
    setTotalDays(newTotalDays);
  }, [rv, startDate, endDate])

  useEffect(() => {
    document.title = pageTitle;
    window.scroll(0, 0);

    if (!sessionStorage.getItem("book"))
      history.push("/");
    else {
      const data = JSON.parse(sessionStorage.getItem("book"));

      if (!data?.rv || !data?.state?.startDate || !data?.state?.endDate)
        return history.push("/");

      setRV(data.rv);
      setStartDate(dateStrToMoment(data.state.startDate));
      setEndDate(dateStrToMoment(data.state.endDate));
      setAddOnsData(data.state.addOnsData);

      loadScriptByURL();
    }
  }, [])

  return (
    <div className={cn("container", styles.main)}>
      <RvHeader />
      <div className="row">
        <div className="col-12 col-lg-8">
          <form className="container-fluid" onSubmit={submit}>
            <div className="row mb-5">
              <div className="col">
                <h2 className="font-weight-bold">Let's start your booking request</h2>
              </div>
            </div>
            <div className="row mb-0 mb-md-5">
              <div className="col-12 col-md-6 mb-3 mb-md-0">
                <input
                  className={styles.inputWrap}
                  type="text"
                  placeholder="First name"
                  value={firstName}
                  onChange={firstNameChanged}
                  required
                />
              </div>
              <div className="col-12 col-md-6 mb-3 mb-md-0">
                <input
                  className={styles.inputWrap}
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={lastNameChanged}
                  required
                />
              </div>
            </div>
            <div className="row mb-0 mb-md-5">
              <div className="col-12 col-md-6 mb-3 mb-md-0">
                <input
                  className={styles.inputWrap}
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={emailChanged}
                  required
                />
              </div>
              <div className="col-12 col-md-6 mb-3 mb-md-0">
                <input
                  className={styles.inputWrap}
                  type="phone"
                  placeholder="Phone number"
                  value={phoneNumber}
                  onChange={phoneNumberChanged}
                  required
                />
              </div>
            </div>
            <div className="row mb-0 mb-md-5">
              <div className="col mb-3 mb-md-0">
                <textarea
                  className={styles.inputWrap}
                  placeholder="Let the owner know more about your trip (optional)"
                  value={description}
                  onChange={descriptionChanged}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                {loadFlag === true &&
                  <button className={styles.buttonWrap}>
                    Requesting to book
                  </button>
                }
                {success === true &&
                  <button className={styles.buttonWrap}>
                    Requested to book
                  </button>
                }
                {success === false &&
                  <button className={styles.buttonWrap}>
                    Failed Request to book
                  </button>
                }
                {(loadFlag === false && success === null) &&
                  <button className={styles.buttonWrap} type="submit">
                    Request to book
                  </button>
                }
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-4 d-flex justify-content-center">
          {rv &&
            <div className={styles.cardWrap}>
              <div className={styles.imageContent}>
                {(rv.primary_image_url || rv.images?.length > 0) &&
                  <img className={styles.img} src={rv.primary_image_url || rv.images[0].url} alt="rv card" />
                }
              </div>
              <div className={cn("container-fluid", styles.textContent)}>
                <div className="row py-4 border-bottom">
                  <div className="col">
                    <h6>{rv.filtered_name}</h6>
                    <p className="m-0">
                      {rv.score ?
                        <span className={cn(styles.colorLabel, "mr-2")}><AiFillStar/> {rv.score}</span> : ""
                      }
                      {rv.display_vehicle_type &&
                        <span>Class B
                          {rv.sleeps || rv.seatbelts ?
                            <span>&nbsp;•&nbsp;</span> : ""
                          }
                        </span>
                      }
                      {rv.sleeps ?
                        <span>Sleep {rv.sleeps}
                          {rv.seatbelts ?
                            <span>&nbsp;•&nbsp;</span> : ""
                          }
                        </span> : ""
                      }
                      {rv.seatbelts ?
                        <span>
                          Seatbelts {rv.seatbelts}
                        </span> : ""
                      }
                    </p>
                  </div>
                </div>
                <div className="row py-4 border-bottom">
                  <div className="col">
                    <RvDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onChange={dateRangeChanged}
                      bookedDatesData={rv?.bookedDates}
                    />
                  </div>
                </div>
                <div className="row py-4 border-bottom">
                  <div className="col d-inline-flex justify-content-between align-items-center">
                    <h5 className="m-0">Pick up</h5>
                    {(rv.location?.city || rv.location?.state) &&
                      <span>{rv.location.city}, {rv.location.state}</span>
                    }
                  </div>
                </div>
                {totalPrice && price && daysPrice && totalDays ?
                  <div className="row py-4 border-bottom">
                    <div className="col">
                      <div className="container-fluid">
                        <div className="row mb-2">
                          <div className="col p-0 d-flex justify-content-between">
                            <span>${formatNumber(price / 100)} * {totalDays} {rv.activeOptions?.use_day_pricing ? "days" : "nights"}</span>
                            <span>${formatNumber(daysPrice)}</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col p-0 d-flex justify-content-between">
                            <span className={styles.colorLabel}>Add-ons {addOnsData?.count ? `(${addOnsData?.count})` : ""}</span>
                            <span>{addOnsData?.price ? `$${formatNumber(addOnsData.price / 100)}` : "-"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : ""
                }
                {totalPrice ?
                  <div className="row py-4 border-bottom">
                    <div className="col d-flex justify-content-between">
                      <span>Total</span>
                      <span>${formatNumber(totalPrice)}</span>
                    </div>
                  </div> : ""
                }
              </div>
            </div>
          }
        </div>
      </div>
      <RvModal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        classes={styles.modal}
      >
        <div className={styles.thanksWrap}>
          <h2 className={styles.thanksTitle}>Thanks for booking.</h2>
          <p className={styles.thanksDescription}>The owner will get back to you soon to process your booking request.</p>
          <div className={styles.buttonWrap}>
            <Link to="/home" className={styles.button}>
              Go To Home
            </Link>
            <Link to="/search" className={styles.button}>
              Go To Search
            </Link>
          </div>
        </div>
      </RvModal>
      <RvFooter />
    </div>
  )
}

const mapStateToProps = (state) => ({
  loadFlag: state.rvs.loadFlag,
  success: state.rvs.success
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, rvService), dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Book)