import {
  RES_LIST_FETCH,
  RES_LIST_FETCH_SUCCESS,
  RES_LIST_FETCH_FAILURE
} from "../constants/actionType";

export const resListFetch = () => {
  return {
    type: RES_LIST_FETCH
  }
}

export const resListFetchSuccess = (data) => {
  return {
    type: RES_LIST_FETCH_SUCCESS,
    data
  }
}

export const resListFetchFailure = (error) => {
  return {
    type: RES_LIST_FETCH_FAILURE,
    error
  }
}