import React, { useEffect } from "react";

const QuickBooksButton = () => {

  const qbLogin = () => {
    const template = `
      <ipp:connectToIntuit></ipp:connectToIntuit>
    `;

    return {
      __html: template
    }
  }

  return (
    <div dangerouslySetInnerHTML={qbLogin()} />
  )
}

export default QuickBooksButton