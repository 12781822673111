import React, { useEffect, useState } from "react";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import ReactLoading from "react-loading";
import styles from "./dateRes.module.sass";
import {
  dateToString,
  dateStrToMoment,
  momentToDate,
  getSubDate,
  getForwardDate
} from "../../util/date";
import { useQuery, getSno } from "../../util/url";
import * as resService from "../../services/resService";

export const DateRes = () => {
  const query = useQuery();
  const [date, setDate] = useState(dateToString(getSubDate(1, dateStrToMoment(new Date()))));
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const load = () => {
    if (query) {
      setData(null);
      setError(null);
      const sno = getSno(query);
      if (!sno) return;
      resService.findDateResList({
        sno,
        date: momentToDate(dateStrToMoment(date))
      })
        .then(response => {
          setData(response);
        })
        .catch(error => {
          setError(error);
        });
    }
  }

  const clickedBeforeDate = () => {
    setDate(dateToString(getSubDate(1, dateStrToMoment(date))));
  }

  const clickForwardDate = () => {
    setDate(dateToString(getForwardDate(1, dateStrToMoment(date))));
  }

  useEffect(() => {
    if (date)
      load();
  }, [date]);

  return (
    <div className={styles.resWrap}>
      <div className={styles.resHeaderWrap}>
        <div>
          <IoCaretBack
            className={styles.cashHeaderNarrow}
            size={22}
            onClick={clickedBeforeDate}
          />
          <input
            type="date"
            value={date}
            onChange={event => setDate(event.target.value)}
          />
          <IoCaretForward
            className={styles.cashHeaderNarrow}
            size={22}
            onClick={clickForwardDate}
          />
        </div>
        <h3>{data?.title || ""}</h3>
      </div>
      {!(data || error) &&
        <div className={styles.loadingContainer}>
          <ReactLoading type="spin" color="#23B5B5" height={50} width={50} />
        </div>
      }
      <table>
        <thead>
          <tr>
            {data?.data?.length > 0 && Object.keys(data.data[0]).map((key, index) => {
              return <th key={index}>{key}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((row, rowIndex) => {
            return <tr key={rowIndex}>
              {Object.entries(row).map(column => {
                return <td key={column[0]} dangerouslySetInnerHTML={{__html: column[1]}} />
              })}
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}

export default DateRes