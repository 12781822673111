import React, {useEffect, useState} from "react";
import RvCollapse from "../rvCollapse";
import styles from "./rvDetailRulesPolicies.module.sass";
import cn from "classnames";
import { IoMusicalNotesOutline } from "react-icons/io5";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { MdPets, MdSmokeFree } from "react-icons/md";
import { BiBath, BiChevronRight, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { formatNumber } from "../../util/number";

const ICON_SIZE = 40;

const rules = [
  {key: "pet_friendly", parentKey: "features", allowedTitle: "Pet friendly", unAllowedTitle: "Pets not allowed", icon: <MdPets size={ICON_SIZE} />},
  {key: "festival_friendly", parentKey: "features", allowedTitle: "Music festival friendly",  unAllowedTitle: "No music festivals", icon: <IoMusicalNotesOutline size={ICON_SIZE} />},
  {key: "tailgate_friendly", parentKey: "features", allowedTitle: "Tailgating friendly", unAllowedTitle: "Tailgating not allowed", icon: <BiBath size={ICON_SIZE} />},
  {key: "smoking_allowed", parentKey: "features", allowedTitle: "Smoking friendly", unAllowedTitle: "No smoking", icon: <MdSmokeFree size={ICON_SIZE} />},
];

const rates = [
  {key: "day_price", parentKey: "activeOptions", title: "Daily"},
  {key: "weekly_rate_per_day", discountKey: "weekly_discount_percentage", parentKey: "activeOptions", title: "Weekly"},
  {key: "monthly_rate_per_day", discountKey: "monthly_discount_percentage", parentKey: "activeOptions", title: "Monthly"},
];

const policies = [
  {
    key: "moderate",
    label: "Moderate",
    text: "Renter is responsible for 25% of booking total if cancelled up to 7 days before pickup, then 50% for remaining days.",
    values: [
      {
        title: "If cancelled more than 7 days prior to departure:",
        values: [
          "Any insurance paid is refunded",
          "Renter is responsible for 25% of the booking total, plus the service fee"
        ]
      },
      {
        title: "If cancelled within 7 days of departure:",
        values: [
          "Any insurance paid is refunded",
          "Renter is responsible for 50% of the booking total, plus the service fee"
        ]
      },
    ]
  },
  {
    key: "flexible",
    label: "Flexible",
    text: "100% refund of booking total up to 5 days before pickup, then renter is responsible for 25% of booking total for remaining days.",
    values: [
      {
        title: "If cancelled more than 5 days prior to departure:",
        values: [
          "Any insurance paid is refunded",
          "100% of the booking total is refunded",
          "The service fee is not refunded"
        ]
      },
      {
        title: "If cancelled within 5 days of departure:",
        values: [
          "Any insurance paid is refunded",
          "Renter is responsible for 25% of the booking total, plus the service fee"
        ]
      },
    ]
  },
  {
    key: "strict",
    label: "Strict",
    text: "Renter is responsible for 50% of booking total if cancelled up to 14 days before pickup, then 100% for remaining days.",
    values: [
      {
        title: "If cancelled more than 14 days prior to departure:",
        values: [
          "Any insurance paid is refunded",
          "Renter is responsible for 50% of the booking total, plus the service fee"
        ]
      },
      {
        title: "If cancelled within 14 days of departure:",
        values: [
          "Any insurance paid is refunded",
          "Renter is responsible for the booking total and the service fee"
        ]
      },
    ]
  }
];

const RvDetailRulesPolicies = ({ rv }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [showPoliciesDetail, setShowPoliciesDetail] = useState(false);
  const [ratesData, setRatesData] = useState(null);
  const [policyIndex, setPolicyIndex] = useState(null);

  useEffect(() => {
    let newRates = [];
    if (rv) {
      if (rv.cancel_policy) {
        policies.map((item, index) => {
          if (item.key === rv.cancel_policy)
            setPolicyIndex(index);
        });
      }

      newRates = rates.map((item) => {
        if (rv[item.parentKey]) {
          if (item.discountKey)
            return {...item, ...{value: rv[item.parentKey][item.key], discount: rv[item.parentKey][item.discountKey]}};
          else
            return {...item, ...{value: rv[item.parentKey][item.key]}};
        } else {
          return {...item};
        }
      });
      setRatesData(newRates);
    }
  }, [rv])

  return (
    <RvCollapse
      trigger={
        <div className={styles.collapseHeader}>
          <div>
            <h4 className="font-weight-bold">Owner Rules and policies</h4>
          </div>
          <div className="d-flex align-items-center">
            {!isOpened &&
              <div>
                <MdPets className="mr-3" size={40} />
                <IoMusicalNotesOutline className="mr-2" size={40} />
                <BiBath className="mr-2" size={40} />
                <MdSmokeFree className="mr-2" size={40} />
              </div>
            }
            <div className={styles.arrowBtn}>
              {isOpened ?
                <BsChevronUp size={18} /> :
                <BsChevronDown size={18} />
              }
            </div>
          </div>
        </div>
      }
      body={
        <div className={styles.collapseBody}>
          <h6 className="font-weight-bold mb-4">Owner rules</h6>
          <div className={cn(styles.blocksWrap, "mb-5")}>
            {rv &&
              rules.map((item, index) => {
                if (rv[item.parentKey] && rv[item.parentKey][item.key]) {
                  return (
                    <div className={styles.block} key={index}>
                      {item.icon}
                      <div>
                        <h6 className={styles.title}>{item.allowedTitle}</h6>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className={cn(styles.block, styles.disabled)} key={index}>
                      {item.icon}
                      <div>
                        <h6 className={styles.title}>{item.unAllowedTitle}</h6>
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
          <h6 className="font-weight-bold mb-4">Rates</h6>
          <div className={cn(styles.blocksWrap, "mb-5")}>
            {ratesData?.map((item, index) => {
              return (
                <div className={styles.block} key={index}>
                  <p className="m-0">{item.title}</p>
                  <div>
                    <h6 className={styles.title}>${item.value / 100 || "0"} / day</h6>
                    {item.discountKey &&
                      <p className={cn(styles.value)}>{item.discount || "0"}% discount</p>
                    }
                  </div>
                </div>
              )
            })}
          </div>
          <div className="mb-4">
            <p className="m-0">Minimum stay</p>
            <h6 className="font-weight-bold">{rv?.activeOptions?.minimum_days || "0"} days</h6>
          </div>
          <div className="mb-4">
            <p className="m-0">Refundable security deposit</p>
            <h6 className="font-weight-bold">${rv?.security_deposit ? formatNumber(rv.security_deposit / 100) : "0"}</h6>
          </div>
          <div className="mb-5">
            <p className="mb-1">24/7 unlimited roadside assistance</p>
            <h6 className={styles.link}>
              Lean more
              <BiChevronRight />
            </h6>
          </div>
          <div>
            <h6 className="font-weight-bold mb-3">Policies</h6>
            <p className="font-weight-bold d-inline-flex align-items-center mb-4">
              Cancellation policy
              <span className={cn(styles.link, "ml-2")}>
                {policyIndex !== null && policies[policyIndex].label}
                <BiChevronRight />
              </span>
            </p>
            <p className="mb-4">
              Cancel within 48 hours of booking to get a full refund, as long as at least 14 days remain before departure.
            </p>
            {policyIndex !== null &&
              <p className="mb-4">
                {policies[policyIndex].text}
              </p>
            }
          </div>
          {(showPoliciesDetail === true && policyIndex !== null) &&
            <div>
              {policies[policyIndex]?.values?.map((item, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <p className="m-0">
                      {item.title}
                    </p>
                    <ul>
                      {item.values?.map((subItem, subIndex) => {
                        return (
                          <li key={subIndex}>
                            {subItem}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          }
          <div className="mb-4">
            {showPoliciesDetail ===  false &&
              <h6 className={styles.link} onClick={() => setShowPoliciesDetail(true)}>
                See details
                <BiChevronDown />
              </h6>
            }
            {showPoliciesDetail === true &&
              <h6 className={styles.link} onClick={() => setShowPoliciesDetail(false)}>
                Hide details
                <BiChevronUp />
              </h6>
            }
          </div>
          <div>
            <h6 className="font-weight-bold">
              Mileage
            </h6>
            <p className="m-0">
              150 miles free per day
            </p>
            <p className="m-0">
              You will be charged $0.50 for every mile over per day.
            </p>
          </div>
        </div>
      }
      setIsOpened={setIsOpened}
    />
  )
}

export default RvDetailRulesPolicies