import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RvHeader from "../../components/rvHeader";
import RvFooter from "../../components/rvFooter";
import RvHero from "../../components/rvHero";
import RvRentz from "../../components/rvRentz";
import RvPopular from "../../components/rvPopular";
import RvHowItWorks from "../../components/rvHowItWorks";
import RvDiscover from "../../components/rvDiscover";
import RvNewsLatter from "../../components/rvNewsLatter";
import * as rvService from "../../services/rvService";
import { getCurrentLocation } from "../../services/locationService";

const pageTitle = "Rentzly - Home";

const Home = ({ popularRVS, actions }) => {
  const history = useHistory();

  const submit = (url) => {
    history.push(url);
  }

  useEffect(() => {
    document.title = pageTitle;
    window.scroll(0, 0);

    const func = async () => {
      await getCurrentLocation().then(response => {
        if (response?.latitude && response.longitude) {
          actions.searchPopularNearByRVS({ lat: response.latitude, lng: response.longitude });
        }
      })
    }

    func();
  }, [])

  return (
    <div>
      <RvHeader />
      <RvHero submit={submit}/>
      <RvRentz />
      <RvPopular rvs={popularRVS} />
      <RvHowItWorks />
      <RvDiscover />
      <RvNewsLatter />
      <RvFooter />
    </div>
  )
}

const mapStateToProps = (state) => ({
  popularRVS: state.rvs.popularNearByData
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, rvService), dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)