import React from "react";
import cn from "classnames";
import styles from "./rvDiscover.module.sass";
import {Link} from "react-router-dom";
import discover1Img from "../../assets/img/discover-1.jpg";
import discover2Img from "../../assets/img/discover-2.jpg";
import discover3Img from "../../assets/img/discover-3.jpg";
import discover4Img from "../../assets/img/discover-4.jpg";
import discover5Img from "../../assets/img/discover-5.jpg";
import discover6Img from "../../assets/img/discover-6.jpg";
import discover7Img from "../../assets/img/discover-7.jpg";
import discover8Img from "../../assets/img/discover-8.jpg";

const RvDiscover = () => {
  return (
    <div className={cn("py-4", "py-md-5", styles.discover)}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={cn("text-center", "mb-4", styles.header)}>
              <h3 className={styles.large}>Discover the best of what’s around.</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className={styles.imageWrap}>
              <Link to="/search">
                <img src={discover1Img} alt="" className={styles.img1} />
                <p className={styles.label}>Discover the best of what’s around.</p>
              </Link>
            </div>
            <div className={styles.imageWrap}>
              <Link to="/home">
                <img src={discover2Img} alt="" className={styles.img2} />
                <p className={styles.label}>Discover the best of what’s around.</p>
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6">
                <div className={styles.imageWrap}>
                  <Link to="/search">
                    <img src={discover3Img} alt="" className={styles.img3} />
                    <p className={styles.label}>Discover the best of what’s around.</p>
                  </Link>
                </div>
                <div className={styles.imageWrap}>
                  <Link to="/search">
                    <img src={discover4Img} alt="" className={styles.img3} />
                    <p className={styles.label}>Discover the best of what’s around.</p>
                  </Link>
                </div>
                <div className={styles.imageWrap}>
                  <Link to="/home">
                    <img src={discover5Img} alt="" className={styles.img3} />
                    <p className={styles.label}>Discover the best of what’s around.</p>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6">
                <div className={styles.imageWrap}>
                  <Link to="/search">
                    <img src={discover6Img} alt="" className={styles.img3} />
                    <p className={styles.label}>Discover the best of what’s around.</p>
                  </Link>
                </div>
                <div className={styles.imageWrap}>
                  <Link to="/search">
                    <img src={discover7Img} alt="" className={styles.img3} />
                    <p className={styles.label}>Discover the best of what’s around.</p>
                  </Link>
                </div>
                <div className={styles.imageWrap}>
                  <Link to="/search">
                    <img src={discover8Img} alt="" className={styles.img3} />
                    <p className={styles.label}>Discover the best of what’s around.</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RvDiscover