import React, {useEffect, useState} from "react";
import cn from "classnames";
import ReactLoading from "react-loading";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  IoCaretForward,
  IoCaretBack
} from "react-icons/io5";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/all";
import styles from "../employeeCash/employeeCash.module.sass";
import {dateStrToMoment, dateToString, getForwardDate, getSubDate} from "../../util/date";
import {ADMIN_NAME, ADMIN_PASS} from "../../config/config";
import * as resService from "../../services/resService";

const columns = ["Employee Name", "Cash Owed", "Actual Cash Counted", "Manager Name", "Comments"];
const initialRow = {employee_name: "", cash_owed: "", actual_cash_counter: "", manager_name: "", comments: ""};
const commentsColumns = ["Manager Name", "Comments"];
const lastWeekCommentsColumns = ["date", "Comments"];
const initialCommentRow = {manager_name: "", comments: ""};

export const EmployeeCash1 = () => {
  const [date, setDate] = useState(null);
  const [sum, setSum] = useState(null);
  const [data, setData] = useState(null);
  const [commentsData, setCommentsData] = useState(null);
  const [lastWeekComments, setLastWeekComments] = useState(null);
  const [data1, setData1] = useState(null);
  const [error, setError] = useState(null);
  const [startFlag, setStartFlag] = useState(true);
  const [totalCashOwed, setTotalCashOwed] = useState(null);
  const [totalActualCashCounted, setTotalActualCashCounted] = useState(null);
  const [showCashDeposits, setShowCashDeposits] = useState(true);
  const [showGeneralComments, setShowGeneralComments] = useState(true);
  const [showLastWeekComments, setShowLastWeekComments] = useState(true);
  const [showCashExpected, setShowCashExpected] = useState(true);

  const load = () => {
    if (!startFlag) return;
    setError(null);
    loadEmployeeCash();
    loadGeneralComments();
    loadLastWeekComments();
    loadEmployeeCash1();
  }

  const loadEmployeeCash = () => {
    setTotalCashOwed(null);
    setTotalActualCashCounted(null);
    resService.getEmployeeCash(date)
      .then(response => {
        let newData = [];
        response?.map(item => {
          if (item?.employee_name?.length > 0 && item?.cash_owed?.length > 0 && item?.actual_cash_counter?.length > 0)
            newData.push(item);
        });
        while (add(newData) !== null)
          add(newData);
        setData(newData);
      })
      .catch(error => {
        setError(error);
      });
  }

  const loadGeneralComments = () => {
    resService.getGeneralComments(date)
      .then(response => {
        let newComments = [];
        response?.map(item => {
          newComments.push(item);
        });
        while (addComment(newComments) !== null)
          addComment(newComments);
        setCommentsData(newComments);
      })
      .catch(error => {
        setError(error);
      });
  }

  const loadLastWeekComments = () => {
    resService.getLastWeekComments(date)
      .then(response => {
        setLastWeekComments(response);
      })
      .catch(error => {
        setError(error);
      });
  }

  const loadEmployeeCash1 = () => {
    resService.getEmployeeCash1(date)
      .then(response => {
        setData1(response);
      })
      .catch((error) => {
        setError(error);
      });
  }

  const add = (newData) => {
    if (newData?.length === 8)
      return null;
    newData.push({...initialRow, ...{isNew: true}});
    return newData;
  }

  const addComment = (comments) => {
    if (comments.length === 5)
      return null;
    comments.push({...initialCommentRow, ...{isNew: true}});
    return comments;
  }

  const save = () => {
    if (!data || data.length === 0)
      return;

    let emptyFlag = true;
    data?.map(item => {
      if (item.employee_name?.length > 0 && item.cash_owed?.length > 0 && item.actual_cash_counter?.length > 0)
        emptyFlag = false;
    });
    if (emptyFlag) return toast("Fill all cells in each row OR delete the row and save.", {type: "info", theme: "colored"});

    let newData = [...data];
    newData = newData.filter(item => item.isNew === true && item.employee_name?.length > 0 && item.cash_owed?.length > 0 && item.actual_cash_counter?.length > 0);
    newData = newData.map((item) => {
      return {
        employee_name: item.employee_name,
        cash_owed: item.cash_owed,
        actual_cash_counter: item.actual_cash_counter,
        manager_name: item.manager_name,
        comments: item.comments,
        date: date
      };
    });

    resService.saveEmployeeCash(newData)
      .then(() => {
        update();
      })
      .catch((error) => {
        setError(error);
      });
  }

  const saveComments = () => {
    if (!commentsData || commentsData.length === 0)
      return;

    let emptyFlag = true;
    commentsData?.map(item => {
      if (item.manager_name?.length > 0 && item.comments?.length > 0)
        emptyFlag = false;
    });
    if (emptyFlag) return toast("Fill all cells in each row OR delete the row and save.", {type: "info", theme: "colored"});

    let newComments = [...commentsData];
    newComments = newComments.filter(item => item.isNew === true && item.manager_name?.length > 0 && item.comments?.length > 0);
    newComments = newComments.map(item => {
      return {
        manager_name: item.manager_name,
        comments: item.comments,
        date: date
      };
    });

    resService.saveGeneralComments(newComments)
      .then(() => {
        updateComments();
      })
      .catch((error) => {
        setError(error);
      });
  }

  const update = () => {
    let newData = [...data];
    newData = newData.filter(item => !item.isNew);

    const updatePromise = async () => {
      return await Promise.all(newData.map(async (item) => {
        await resService.updateEmployeeCash(item.id, item);
      }));
    }

    updatePromise().then(() => {
      loadEmployeeCash();
      toast("Saved Successfully!", {type: "success", theme: "colored"});
    });
  }

  const updateComments = () => {
    let newComments = [...commentsData];
    newComments = newComments.filter(item => !item.isNew);

    const updatePromise = async () => {
      return await Promise.all(newComments.map(async (item) => {
        await resService.updateGeneralComment(item.id, item);
      }));
    }

    updatePromise().then(() => {
      loadGeneralComments();
      toast("Saved Successfully!", {type: "success", theme: "colored"});
    });
  }

  const deleteRow = (id) => {
    if (!id) return loadEmployeeCash();
    resService.deleteEmployeeCash(id)
      .then(() => {
        loadEmployeeCash();
        toast("Deleted Successfully!", {type: "success", theme: "colored"});
      })
      .catch((error) => {
        setError(error);
      });
  }

  const deleteComment = (id) => {
    if (!id) return loadGeneralComments();
    resService.deleteGeneralComment(id)
      .then(() => {
        loadGeneralComments();
        toast("Deleted Successfully!", {type: "success", theme: "colored"});
      })
      .catch(error => {
        setError(error);
      });
  }

  const changedCell = (e, index, key) => {
    const newData = [...data];
    newData[index][key] = e.target.value;
    setData(newData);
  }

  const changedComment = (e, index, key) => {
    const newComments = [...commentsData];
    newComments[index][key] = e.target.value;
    setCommentsData(newComments);
  }

  const clickedBeforeDate = () => {
    setDate(dateToString(getSubDate(1, dateStrToMoment(date))));
  }

  const clickForwardDate = () => {
    setDate(dateToString(getForwardDate(1, dateStrToMoment(date))));
  }

  useEffect(() => {
    if (date)
      load();
  }, [date])

  useEffect(() => {
    if (data1?.length) {
      let totalAmount = 0;
      data1.map(item => {
        totalAmount += item.amount;
      });
      totalAmount = totalAmount.toFixed(2);
      setSum(totalAmount);
    } else
      setSum(null);
  }, [data1])

  useEffect(() => {
    let newCashOwed = null;
    let newActualCashOwed = null;
    data?.map(item => {
      if (item.cash_owed?.length > 0) {
        const cashOwed = item.cash_owed.replace('$', '').replace(' ', '');
        if (!isNaN(Number(cashOwed)))
          newCashOwed = newCashOwed ? newCashOwed + Number(cashOwed) : Number(cashOwed);
      }

      if (item.actual_cash_counter?.length > 0) {
        const actualCashCounter = item.actual_cash_counter.replace('$', '').replace(' ', '');
        if (!isNaN(Number(actualCashCounter)))
          newActualCashOwed = newActualCashOwed ? newActualCashOwed + Number(actualCashCounter) : Number(actualCashCounter);
      }
    });
    setTotalCashOwed(newCashOwed ? newCashOwed?.toFixed(2) : null);
    setTotalActualCashCounted(newActualCashOwed ? newActualCashOwed?.toFixed(2) : null);
  }, [data, totalCashOwed, totalActualCashCounted])

  useEffect(() => {
    if (startFlag)
      setDate(dateToString(new Date()));
  }, [startFlag])

  // useEffect(() => {
  //   let adminName = prompt("Name Here:","");
  //   let adminPass = prompt("Password Here:","");
  //
  //   if (adminName === ADMIN_NAME && adminPass === ADMIN_PASS)
  //     setStartFlag(true);
  //
  //   while(adminName !== ADMIN_NAME || adminPass !== ADMIN_PASS) {
  //     alert("Invalid name and password");
  //     adminName = prompt("Name Here:","");
  //     adminPass = prompt("Password Here:","");
  //     if (adminName === ADMIN_NAME && adminPass === ADMIN_PASS)
  //       setStartFlag(true);
  //   }
  // }, [])

  return (
    startFlag === true &&
      <div className={styles.cashContainer}>
        <div className={styles.cashHeaderContainer}>
          <div>
            <IoCaretBack
              className={styles.cashHeaderNarrow}
              size={22}
              onClick={clickedBeforeDate}
            />
            <input
              type="date"
              value={date || ""}
              onChange={event => setDate(event.target.value)}
            />
            <IoCaretForward
              className={styles.cashHeaderNarrow}
              size={22}
              onClick={clickForwardDate}
            />
          </div>
        </div>
        <div className={styles.cashHeaderContainer}>
          <p className={styles.cashHeaderLabel} onClick={() => setShowCashDeposits(!showCashDeposits)}>
            Cash Deposits
            {showCashDeposits ?
              <AiOutlineMinusSquare class={styles.cashHeaderIcon} color="#23B5B5" /> :
              <AiOutlinePlusSquare class={styles.cashHeaderIcon} color="#23B5B5" />
            }
          </p>
          {showCashDeposits &&
            <div>
              <button
                className={cn(styles.addButton, styles.saveButton)}
                onClick={save}
              >
                Save
              </button>
            </div>
          }
        </div>
        {showCashDeposits &&
          <table className={styles.cashTable}>
            <thead>
            <tr>
              <th>No</th>
              {columns.map((column, index) => {
                return (
                  <th key={index}>{column}</th>
                )
              })}
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {data?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    <input
                      className={styles.cellInput}
                      type="text"
                      value={row.employee_name || ""}
                      onChange={(e) => changedCell(e, index, "employee_name")}
                    />
                  </td>
                  <td>
                    <input
                      className={styles.cellInput}
                      type="text"
                      value={row.cash_owed || ""}
                      onChange={(e) => changedCell(e, index, "cash_owed")}
                    />
                  </td>
                  <td>
                    <input
                      className={styles.cellInput}
                      type="text"
                      value={row.actual_cash_counter || ""}
                      onChange={(e) => changedCell(e, index, "actual_cash_counter")}
                    />
                  </td>
                  <td>
                    <input
                      className={styles.cellInput}
                      type="text"
                      value={row.manager_name || ""}
                      onChange={(e) => changedCell(e, index, "manager_name")}
                    />
                  </td>
                  <td>
                      <textarea
                        className={styles.cellInput}
                        value={row.comments || ""}
                        onChange={(e) => changedCell(e, index, "comments")}
                      />
                  </td>
                  <td>
                    <button onClick={() => deleteRow(row.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })}
            {totalCashOwed && totalActualCashCounted &&
            <tr>
              <td>Total</td>
              <td></td>
              <td>{totalCashOwed}</td>
              <td>{totalActualCashCounted}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            }
            </tbody>
          </table>
        }
        {!(data || error) && <div className={styles.loadingContainer}><ReactLoading className={styles.loading} type="spin" color="#23B5B5" height={50} width={50} /></div>}
        <div className={styles.cashHeaderContainer}>
          <div>
            <p className={styles.cashHeaderLabel} onClick={() => setShowGeneralComments(!showGeneralComments)}>
              General Comments
              {showGeneralComments ?
                <AiOutlineMinusSquare class={styles.cashHeaderIcon} color="#23B5B5" /> :
                <AiOutlinePlusSquare class={styles.cashHeaderIcon} color="#23B5B5" />
              }
            </p>
          </div>
          {showGeneralComments &&
            <div>
              <button
                className={cn(styles.addButton, styles.saveButton)}
                onClick={saveComments}
              >
                Save
              </button>
            </div>
          }
        </div>
        {showGeneralComments &&
          <table className={styles.cashTable}>
            <thead>
            <tr>
              <th>No</th>
              {commentsColumns.map((column, index) => {
                return (
                  <th key={index}>{column}</th>
                )
              })}
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {commentsData?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      className={styles.cellInput}
                      type="text"
                      value={row.manager_name || ""}
                      onChange={(e) => changedComment(e, index, "manager_name")}
                    />
                  </td>
                  <td>
                    <textarea
                      className={styles.cellInput}
                      value={row.comments || ""}
                      onChange={(e) => changedComment(e, index, "comments")}
                    />
                  </td>
                  <td>
                    <button onClick={() => deleteComment(row.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        }
        <div className={styles.cashHeaderContainer}>
          <div>
            <p className={styles.cashHeaderLabel} onClick={() => setShowLastWeekComments(!showLastWeekComments)}>
              Last Week General Comments Summary
              {showLastWeekComments ?
                <AiOutlineMinusSquare class={styles.cashHeaderIcon} color="#23B5B5" /> :
                <AiOutlinePlusSquare class={styles.cashHeaderIcon} color="#23B5B5" />
              }
            </p>
          </div>
        </div>
        {showLastWeekComments &&
          <table className={styles.cashTable}>
            <thead>
            <tr>
              <th>No</th>
              {lastWeekCommentsColumns.map((column, index) => {
                return (
                  <th key={index}>{column}</th>
                )
              })}
            </tr>
            </thead>
            <tbody>
            {lastWeekComments?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.date || ""}</td>
                  <td className="text-left">
                    {row.comments?.map((rowItem, rowIndex) => {
                      return (<p key={rowIndex}>
                        {rowItem.manager_name}:
                        <ul>
                          {rowItem.content?.map((commentCell, commentCellIndex) => {
                            return (<li key={commentCellIndex}>{commentCell}</li>)
                          })}
                        </ul>
                      </p>)
                    })}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        }
        {data1?.length > 0 && <div className={styles.cashHeaderContainer}>
          <p className={styles.cashHeaderLabel} onClick={() => !setShowCashExpected(!showCashExpected)}>
            Cash Expected
            {showCashExpected ?
              <AiOutlineMinusSquare class={styles.cashHeaderIcon} color="#23B5B5" /> :
              <AiOutlinePlusSquare class={styles.cashHeaderIcon} color="#23B5B5" />
            }
          </p>
        </div>}
        {showCashExpected &&
        <table className={styles.cashTable}>
          <thead>
          <tr>
            {data1?.length > 0 && Object.keys(data1[0]).map((key, index) => {
              return <th key={index}>{key}</th>
            })}
          </tr>
          </thead>
          <tbody>
          {data1?.map((row, rowIndex) => {
            return <tr key={rowIndex}>
              {Object.entries(row).map(column => {
                return <td key={column[0]} dangerouslySetInnerHTML={{__html: column[1]}} />
              })}
            </tr>
          })}
          </tbody>
        </table>
        }

        {sum && showCashExpected && <h4 className={styles.cashSum}>{'Sum: ' + sum}</h4>}
        {error}
        <ToastContainer hideProgressBar={true} />
      </div>
  )
}

export default EmployeeCash1