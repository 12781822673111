import React, {useRef} from "react";
import { GoogleMap, withGoogleMap } from "react-google-maps";
import transparentImg from "../../assets/images/transpareent.png";
import { AiOutlineCar } from "react-icons/ai"

const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const options = {
  defaultZoom: 10,
  defaultCenter: { lat: 40.739, lng: -74.042 },
  streetViewControl: false,
  scrollwheel: false,
  disableDefaultUI: true,
  markerLabel: {
    style: {
      color: "#2bacac",
      backgroundColor: "#fff",
      fontSize: "0.9rem",
      fontWeight: "bold",
      padding: "0.4rem 0.6rem",
      border: "0.15rem solid #2bacac",
      borderRadius: "2rem"
    },
    label: {
      style: { display: "flex", alignItems: "center" },
      size: 60
    }
  }
}

const RvDetailMap = ({ location }) => {
  const mapRef = useRef(null);

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={options.defaultZoom}
      defaultCenter={location?.lat && location?.lng ? location : options.defaultCenter}
      center={location?.lat && location?.lng ? location : options.defaultCenter}
      options={{
        streetViewControl: options.streetViewControl,
        scrollwheel: options.scrollwheel, // allow scroll wheel
        disableDefaultUI: options.disableDefaultUI,
        draggable: false
      }}
    >
      <MarkerWithLabel
        position={{ lat: location?.lat ? location.lat : options.defaultCenter.lat, lng: location?.lng ? location.lng : options.defaultCenter.lng }}
        labelAnchor={ new window.google.maps.Point(30, 30) }
        labelStyle={options.markerLabel.style}
        icon={{ url: transparentImg }}
      >
        <span style={ options.markerLabel.label.style }>
          <AiOutlineCar size={ options.markerLabel.label.size } />
        </span>
      </MarkerWithLabel>
    </GoogleMap>
  )
}

export default withGoogleMap(RvDetailMap)