import React from "react";
import styles from "./rvDetailImage.module.sass";

const ImageItem = ({ index, url, onClick }) => {
  return (
    <div className={styles.imageItem} onClick={() => {onClick(index)}}>
      <img className={styles.image} src={url} alt="rv" />
    </div>
  )
}

const RvDetailImages = ({ data, onClick }) => {
  return (
    <div className={styles.imagesWrap}>
      {data?.length > 3 &&
        <div className={styles.imagesGrid}>
          {data?.map((item, index) => {
            if (index < 4)
              return (
                <ImageItem key={index} index={index} url={item.url} onClick={onClick} />
              )
          })}
        </div>
      }
      {data?.length === 3 &&
        <div className={styles.imagesThreeGrid}>
          {data?.map((item, index) => {
            return (
              <ImageItem key={index} index={index} url={item.url} onClick={onClick} />
            )
          })}
        </div>
      }
      {data?.length === 2 &&
        <div className={styles.imagesTwoGrid}>
          {data?.map((item, index) => {
            return (
              <ImageItem key={index} index={index} url={item.url} onClick={onClick} />
            )
          })}
        </div>
      }
      {data?.length === 1 &&
        <div className={styles.imagesOneGrid}>
          {data?.map((item, index) => {
            return (
              <ImageItem key={index} index={index} url={item.url} onClick={onClick} />
            )
          })}
        </div>
      }
    </div>
  )
}

export default RvDetailImages