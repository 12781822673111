import {
  geocodeByPlaceId,
  getLatLng
} from "react-google-places-autocomplete";

export const getGeoCodeByPlaceId = async (id) => {
  let data = null;
  await geocodeByPlaceId(id).then((results) => {
    data = results;
  });
  return data;
}

export const getLatLngByGeoCode = async (geoCode) => {
  let data = null;
  await getLatLng(geoCode).then((result) => {
    data = result;
  });
  return data;
}