import {
  RES_LIST_FETCH_SUCCESS,
  RES_LIST_FETCH_FAILURE
} from "../constants/actionType";

const initState = {
  data: null,
  errorMessage: null
};

const resReducer = (state, action) => {
  state = state || initState;

  switch (action.type) {
    case RES_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        data: action.data
      });

    case RES_LIST_FETCH_FAILURE:
      return Object.assign({}, state, {
        errorMessage: action.error
      });

    default:
      return state;
  }
};

export default resReducer