import React, {useEffect, useState} from "react";
import cn from "classnames";
import { Link, useLocation, useHistory } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./rvHeader.module.sass";
import RvLocationInput from "../rvLocationInput";
import RvDateRangePicker from "../rvDateRangePicker";
import { getSearchUrlByAddressLatLng } from "../../util/url";
import { FRESH_DESK_HELP_URL } from "../../config/config";
import logoImg from "../../assets/img/logo.png";

const homePath = "/home";
const searchPath = "/search";

const RvHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const [isHome, setIsHome] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [isLocationChanged, setIsLocationChanged] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const locationChanged = (data) => {
    setUserLocation(data);
    if (data?.label) {
      setIsLocationChanged(true);
    }
  }

  const dateRangeChanged = (data) => {
    if (data) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
  }

  const searchButtonClicked = async () => {
    collapse();
    await goSearch();
  }

  const goSearch = async () => {
    const url = await getSearchUrlByAddressLatLng({
      address: userLocation?.label,
      lat: userLocation?.lat,
      lng: userLocation?.lng,
      startDate: startDate?.format("YYYY-MM-DD"),
      endDate: endDate?.format("YYYY-MM-DD")
    });

    setIsLocationChanged(false);

    if (url)
      history.push(url);
  }

  const collapse = () => {
    const eles = document.getElementsByClassName("navbar-collapse");
    if (eles?.length < 1)
      return;

    if (eles[0].classList?.contains("show"))
      eles[0].classList.remove("show");
  }

  useEffect(() => {
    setIsHome(location.pathname === homePath);
    setIsSearch(location.pathname === searchPath);
    if (location.pathname !== searchPath) {
      setUserLocation(null);
      setStartDate(null);
      setEndDate(null);
      setIsLocationChanged(null);
    }
  }, [location])

  useEffect(() => {
    if (isSearch === false)
      return;

    const func = async () => {
      await goSearch();
    }

    func();
  }, [userLocation, startDate, endDate])

  return (
    <OutsideClickHandler onOutsideClick={collapse}>
      <header>
        <nav className={cn("navbar", "navbar-expand-md", "navbar-dark", "fixed-top", "bg-dark", styles.customNav)}>
          <Link className="navbar-brand" to="/home" onClick={collapse}>
            <img src={logoImg} alt="logo" />
          </Link>
          <button
            className={cn("navbar-toggler", styles.customNavToggler)}
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">

            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              {isHome === false &&
                <li className={cn("nav-item", styles.customNavItem)}>
                  <div className={styles.wrap}>
                    <div className={styles.location}>
                      <RvLocationInput
                        location={userLocation}
                        onChange={locationChanged}
                      />
                    </div>
                    <div className={styles.dateRange}>
                      <RvDateRangePicker
                        isLocationChanged={isLocationChanged}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={dateRangeChanged}
                      />
                    </div>
                    {isSearch === false &&
                      <button
                        className={cn(styles.customNavLink, styles.listRV, styles.customShadow, styles.searchBtn)}
                        onClick={searchButtonClicked}
                      >
                        Search
                      </button>
                    }
                  </div>
                </li>
              }
              <li className={cn("nav-item", styles.customNavItem)}>
                <div className={cn("nav-link", styles.customNavLink)} onClick={() => {
                  collapse();
                  window.open(FRESH_DESK_HELP_URL);
                }}>
                  Help <span className="sr-only">(current)</span>
                </div>
              </li>
              <li className={cn("nav-item", styles.customNavItem)}>
                <Link className={cn("nav-link", styles.customNavLink, styles.listRV, styles.customShadow)} to="/list-your-rv" onClick={collapse}>
                  List your RV
                </Link>
              </li>
              <li className={cn("nav-item", styles.customNavItem)}>
                <Link className={cn("nav-link", styles.customNavLink)} to="/about" onClick={collapse}>
                  About Us
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </OutsideClickHandler>
  )
}

export default RvHeader