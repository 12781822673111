import React from "react";
import {Link} from "react-router-dom";
import cn from "classnames";
import styles from "./rvNewsLatter.module.sass";
import sentImg from "../../assets/img/sent.svg";

const RvNewsLatter = () => {
  return (
    <div className={cn("py-4", "py-md-5", styles.newsLatter)}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className={cn("text-center", "text-md-left", styles.quote)}>
              <p className={styles.label}>
                Be the first to get doses of <span className={styles.colorText}>destination  inspiration,</span>
                and <br />
                <span className={styles.colorText}>

                  </span>
                discount codes.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.newsInput}>
              <label className={cn("d-flex", "bg-white", styles.label)}>
                <input type="text" name="news" className={cn("form-control", styles.input)} placeholder="Enter your email" />
                <button type="submit" className={styles.btn}>
                  <img src={sentImg} alt="sent" />
                </button>
              </label>
              <p>
                We care about the protection of your data. <Link className={styles.link} to="/search">Read our privacy policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RvNewsLatter