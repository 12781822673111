import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";
import styles from "./rvFooter.module.sass";
import logoImg from "../../assets/img/logo.png";
import mesImg from "../../assets/img/mes.svg";
import phoneImg from "../../assets/img/phone.svg";
// import googleImg from "../../assets/img/google.svg";
// import facebookImg from "../../assets/img/facebook.svg";
// import twitterImg from "../../assets/img/twitter.svg";
// import instaImg from "../../assets/img/insta.svg";
// import linkImg from "../../assets/img/link.svg";
import { FACEBOOK_SOCIAL_URL, FRESH_DESK_NEW_TICKET_URL } from "../../config/config";

const privacyPolicyUrl = "https://www.outdoorsy.com/help/privacy-policy";
const termsConditionsUrl = "https://www.outdoorsy.com/help/outdoorsy-terms-and-conditions";

const RvFooter = () => {
  return (
    <footer className={styles.footer}>
      <div className={cn("py-4", "py-md-5", styles.footerTop)}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className={cn("text-white", "text-center", "text-lg-left", styles.footerContainer)}>
                <img src={logoImg} alt="logo" className="mb-3" />
                <p className={styles.about}>Have a question or need help? <br /> Get in touch with us and we <br /> will
                  be happy to help.
                </p>
                <div className={cn("d-flex", "justify-content-center", "justify-content-lg-start", styles.contact)}>
                  <div>
                    <img className={styles.image} src={mesImg} alt="" />
                  </div>
                  <div className="ml-2">
                    <p className={styles.label}>
                      rentzly.support@gmail.com
                    </p>
                  </div>
                </div>
                <div className={cn("d-flex", "justify-content-center", "justify-content-lg-start", styles.contact)}>
                  <div>
                    <img className={styles.image} src={phoneImg} alt="" />
                  </div>
                  <div className="ml-2">
                    <p className={styles.label}>
                      +717 220 3417
                    </p>
                  </div>
                </div>
                <div className={cn("d-flex", "justify-content-center", "justify-content-lg-start", styles.contact)}>
                  <div>
                    <p className={styles.item} onClick={() => {
                      if (FACEBOOK_SOCIAL_URL)
                        window.open(FRESH_DESK_NEW_TICKET_URL);
                    }}>
                      Open Ticket
                      <BiChevronRight />
                    </p>
                  </div>
                </div>
                <div className={cn("d-flex", "justify-content-center", "justify-content-lg-start", styles.contact)}>
                  <div>
                    <p className={cn(styles.item, "d-flex", "align-items-center")} onClick={() => {
                      if (FACEBOOK_SOCIAL_URL)
                        window.open(FACEBOOK_SOCIAL_URL);
                    }}>
                      Check Us on Facebook &nbsp;
                      <FaFacebook size={23} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className={cn("text-center", "text-lg-left", "mb-3", "mb-lg-0", styles.footerContainer)}>
                <h3 className={styles.large}>About</h3>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <Link className={styles.link} to="/about">
                      About us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 ">
              <div className={cn("text-center", "text-lg-left", "mb-3", "mb-lg-0", styles.footerContainer)}>
                <h3 className={styles.large}>PRIVACY</h3>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <div className={styles.link} onClick={() => {
                      window.open(privacyPolicyUrl);
                    }}>
                      Privacy Policy
                    </div>
                  </li>
                  <li className={styles.item}>
                    <div className={styles.link} onClick={() => {
                      window.open(termsConditionsUrl);
                    }}>
                      Terms and Conditions
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn("py-3", styles.footerBottom)}>
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <div className="copy-right text-center text-md-left">
                <p className="mb-0 text-white ">RentZLY. All rights reserved. Made with </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className={cn("text-md-right", "text-center", styles.socialMedia)}>
                {/*<ul className={styles.list}>*/}
                {/*  <li className={styles.item}>*/}
                {/*    <Link to="# ">*/}
                {/*      <img src={googleImg} alt=" " />*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*  <li className={styles.item}>*/}
                {/*    <div onClick={() => {*/}
                {/*      if (FACEBOOK_SOCIAL_URL)*/}
                {/*        window.open(FACEBOOK_SOCIAL_URL);*/}
                {/*    }}>*/}
                {/*      <img src={facebookImg} alt=" " />*/}
                {/*    </div>*/}
                {/*  </li>*/}
                {/*  <li className={styles.item}>*/}
                {/*    <Link to="# ">*/}
                {/*      <img src={twitterImg} alt=" " />*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*  <li className={styles.item}>*/}
                {/*    <Link to="# ">*/}
                {/*      <img src={instaImg} alt=" " />*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*  <li className={styles.item}>*/}
                {/*    <Link to="# ">*/}
                {/*      <img src={linkImg} alt=" " />*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default RvFooter