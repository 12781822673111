import {
  RVS_SEARCH_FETCH,
  RVS_SEARCH_FETCH_SUCCESS,
  RVS_SEARCH_FETCH_FAILURE,
  RVS_POPULAR_NEAR_BY_FETCH,
  RVS_POPULAR_NEAR_BY_FETCH_SUCCESS,
  RVS_POPULAR_NEAR_BY_FETCH_FAILURE,
  RVS_ITEM_FETCH,
  RVS_ITEM_FETCH_SUCCESS,
  RVS_ITEM_FETCH_FAILURE,
  RVS_ITEM_BOOK,
  RVS_ITEM_BOOK_SUCCESS,
  RVS_ITEM_BOOK_FAILURE
} from "../constants/actionType";

export const rvsSearchFetch = () => {
  return {
    type: RVS_SEARCH_FETCH
  }
}

export const rvsSearchFetchSuccess = (data) => {
  return {
    type: RVS_SEARCH_FETCH_SUCCESS,
    data,
  }
}

export const rvsSearchFetchFailure = (error) => {
  return {
    type: RVS_SEARCH_FETCH_FAILURE,
    error
  }
}

export const rvsPopularNearByFetch = () => {
  return {
    type: RVS_POPULAR_NEAR_BY_FETCH
  }
}

export const rvsPopularNearByFetchSuccess = (data) => {
  return {
    type: RVS_POPULAR_NEAR_BY_FETCH_SUCCESS,
    data
  }
}

export const rvsPopularNearByFetchFailure = (error) => {
  return {
    type: RVS_POPULAR_NEAR_BY_FETCH_FAILURE,
    error
  }
}

export const rvsItemFetch = () => {
  return {
    type: RVS_ITEM_FETCH
  }
}

export const rvsItemFetchSuccess = (data) => {
  return {
    type: RVS_ITEM_FETCH_SUCCESS,
    data
  }
}

export const rvsITemFetchFailure = (error) => {
  return {
    type: RVS_ITEM_FETCH_FAILURE,
    error
  }
}

export const rvsItemBook = () => {
  return {
    type: RVS_ITEM_BOOK
  }
}

export const rvsItemBookSuccess = () => {
  return {
    type: RVS_ITEM_BOOK_SUCCESS
  }
}

export const rvsItemBookFailure = (error) => {
  return {
    type: RVS_ITEM_BOOK_FAILURE,
    error
  }
}