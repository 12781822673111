import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./rangeSlider.module.sass";
import cn from "classnames";

const RangeSlider = ({ min, max, onChange}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const rangeRef = useRef(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (rangeRef.current) {
      rangeRef.current.style.left = `${minPercent}%`;
      rangeRef.current.style.width = `${maxPercent - minPercent}`;
    }
  }, [minVal, getPercent])

  useEffect(() => {
    onChange({ min: minVal, max: maxVal});
  }, [minVal, maxVal, onChange])

  return (
    <div className={styles.container}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(e) => {
          const value = Math.min(Number(e.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className={cn(styles.thumb, styles.thumbLeft)}
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(e) => {
          const value = Math.max(Number(e.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className={cn(styles.thumb, styles.thumbRight)}
      />
      <div className={styles.slider}>
        <div className={styles.sliderTrack} />
        <div ref={rangeRef} className={styles.sliderRange} />
      </div>
    </div>
  )
}

export default RangeSlider