import React, {useEffect, useState} from "react";
import cn from "classnames";
import RvCollapse from "../rvCollapse";
import styles from "./rvDetailAmenities.module.sass";
import { BiBed } from "react-icons/bi";
import { RiDvdLine } from "react-icons/ri";
import { CgViewComfortable } from "react-icons/cg";
import { MdComputer, MdStorage } from "react-icons/md";
import { SiAirtable, SiApacheairflow } from "react-icons/si";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CgSmartHomeRefrigerator, CgArrowsExchangeAlt } from "react-icons/cg";
import {
  AiOutlineCamera,
  AiOutlineAudio,
  AiOutlineWifi
} from "react-icons/ai";
import {
  FaToilet,
  FaShower,
  FaSolarPanel,
  FaDAndD,
  FaSatelliteDish
} from "react-icons/fa";
import {
  GiShower,
  GiRadioTower,
  GiSinkingShip,
  GiTheaterCurtains,
  GiPowerGenerator,
  GiChickenOven,
  GiUnionJack,
  GiFoundryBucket,
  GiComputerFan,
  GiDutchBike
} from "react-icons/gi";

const ICON_SIZE = 40;

const list = [
  {key: "toilet", parentKey: "features", title: "Toilet", icon: <FaToilet className="mr-2" size={ICON_SIZE} key={1} />},
  {key: "inside_shower", parentKey: "features", title: "Inside Shower", icon: <FaShower className="mr-2" size={ICON_SIZE} key={2} />},
  {key: "outside_shower", parentKey: "features", title: "Outside Shower", icon: <GiShower className="mr-2" size={ICON_SIZE} key={3} />},
  {key: "solar", parentKey: "features", title: "Solar", icon: <FaSolarPanel className="mr-2" size={ICON_SIZE} key={4} />},
  {key: "radio", parentKey: "features", title: "Radio", icon: <GiRadioTower className="mr-2" size={ICON_SIZE} key={5} />},
  {key: "tv_dvd", parentKey: "features", title: "TV / DVD", icon: <MdComputer className="mr-2" size={ICON_SIZE} key={6} />},
  {key: "dining_table", parentKey: "features", title: "Dining Table", icon: <SiAirtable className="mr-2" size={ICON_SIZE} key={7} />},
  {key: "kitchen_sink", parentKey: "features", title: "Kitchen Sink", icon: <GiSinkingShip className="mr-2" size={ICON_SIZE} key={8} />},
  {key: "microwave", parentKey: "features", title: "Microwave", icon: <GiSinkingShip className="mr-2" size={ICON_SIZE} key={9} />},
  {key: "refrigerator", parentKey: "features", title: "Refrigerator", icon: <CgSmartHomeRefrigerator className="mr-2" size={ICON_SIZE} key={10} />},
  {key: "backup_camera", parentKey: "features", title: "Backup Camera", icon: <AiOutlineCamera className="mr-2" size={ICON_SIZE} key={11} />},
  {key: "extra_storage", parentKey: "features", title: "Extra Storage", icon: <MdStorage className="mr-2" size={ICON_SIZE} key={12} />},
  {key: "inverter", parentKey: "features", title: "Inverter", icon: <CgArrowsExchangeAlt className="mr-2" size={ICON_SIZE} key={13} />},
  {key: "ceiling_fan", parentKey: "features", title: "Ceiling Fan", icon: <GiComputerFan className="mr-2" size={ICON_SIZE} key={14} />},
  {key: "heater", parentKey: "features", title: "Heater", icon: <GiTheaterCurtains className="mr-2" size={ICON_SIZE} key={15} />},
  {key: "beds_queen", parentKey: "features", title: "1 queen", icon: <BiBed className="mr-2" size={ICON_SIZE} key={16} />},
  {key: "generator", parentKey: "features", title: "Generator", icon: <GiPowerGenerator className="mr-2" size={ICON_SIZE} key={17} />},
  {key: "audio_inputs", parentKey: "features", title: "Audio Inputs", icon: <AiOutlineAudio className="mr-2" size={ICON_SIZE} key={18} />},
  {key: "cd_player", parentKey: "features", title: "CD Player", icon: <RiDvdLine className="mr-2" size={ICON_SIZE} key={19} />},
  {key: "satellite", parentKey: "features", title: "Satellite", icon: <FaSatelliteDish className="mr-2" size={ICON_SIZE} key={20} />},
  {key: "wifi", parentKey: "features", title: "Wi-Fi", icon: <AiOutlineWifi className="mr-2" size={ICON_SIZE} key={21} />},
  {key: "oven", parentKey: "features", title: "Oven", icon: <GiChickenOven className="mr-2" size={ICON_SIZE} key={22} />},
  {key: "handicap_accessible", parentKey: "features", title: "ADA Accessible", icon: <FaDAndD className="mr-2" size={ICON_SIZE} key={23} />},
  {key: "awning", parentKey: "features", title: "Awning", icon: <CgViewComfortable className="mr-2" size={ICON_SIZE} key={24} />},
  {key: "bike_rack", parentKey: "features", title: "Bike Rack", icon: <GiDutchBike className="mr-2" size={ICON_SIZE} key={25} />},
  {key: "leveling_jacks", parentKey: "features", title: "Leveling Jacks", icon: <GiUnionJack className="mr-2" size={ICON_SIZE} key={26} />},
  {key: "tow_hitch", parentKey: "features", title: "Tow Hitch", icon: <GiRadioTower className="mr-2" size={ICON_SIZE} key={27} />},
  {key: "washer_dryer", parentKey: "features", title: "Washer / Dryer", icon: <GiFoundryBucket className="mr-2" size={ICON_SIZE} key={28} />},
  {key: "air_conditioner", parentKey: "features", title: "Air Conditioner", icon: <SiApacheairflow className="mr-2" size={ICON_SIZE} key={29} />},
];

const initCount = 3;

const RvDetailAmenities = ({ rv }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [availableList, setAvailableList] = useState(null);
  const [unavailableList, setUnAvailableList] = useState(null);

  useEffect(() => {
    let newAvailableList = [];
    let newUnAvailableList = [];

    if (rv) {
      list.map(item => {
        if (rv[item.parentKey] && rv[item.parentKey][item.key]) {
          newAvailableList.push(item);
        } else {
          newUnAvailableList.push(item);
        }
      });

      setAvailableList(newAvailableList);
      setUnAvailableList(newUnAvailableList);
    }
  }, [rv])

  return (
    <RvCollapse
      trigger={
        <div className={styles.collapseHeader}>
          <div>
            <h4 className="font-weight-bold">Amenities</h4>
          </div>
          <div className="d-flex align-items-center">
            {(!isOpened && availableList?.length > initCount) &&
              <div>
                {availableList?.map((item, index) => {
                  if (index < initCount)
                    return (
                      item.icon
                    )
                })}
                <span className="font-weight-bold mr-2">+{availableList.length - initCount}</span>
              </div>
            }
            <div className={styles.arrowBtn}>
              {isOpened ?
                <BsChevronUp size={18} /> :
                <BsChevronDown size={18} />
              }
            </div>
          </div>
        </div>
      }
      body={
        <div className={styles.collapseBody}>
          <div className={styles.blocksWrap}>
            {availableList?.map((item, index) => {
              return (
                <div className={styles.block} key={index}>
                  {item.icon}
                  <div>
                    <h6 className={styles.title}>{item.title}</h6>
                  </div>
                </div>
              )
            })}
          </div>
          {showUnavailable === true &&
            <p className={styles.link} onClick={() => setShowUnavailable(false)}>
              Hide unavailable amenities
              <BsChevronUp className="ml-1"/>
            </p>
          }
          {showUnavailable === false &&
            <p className={styles.link} onClick={() => setShowUnavailable(true)}>
              Show unavailable amenities
              <BsChevronDown className="ml-1" />
            </p>
          }
          {showUnavailable === true &&
            <div className={cn(styles.blocksWrap, styles.disable, "mt-3", "mb-0")}>
              {unavailableList?.map((item, index) => {
                return (
                  <div className={styles.block} key={index}>
                    {item.icon}
                    <div>
                      <h6 className={styles.title}>{item.title}</h6>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
      }
      setIsOpened={setIsOpened}
    />
  )
}

export default RvDetailAmenities