import React, {useEffect, useState} from "react";
import 'react-dates/initialize';
import {DateRangePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import "./rvDateRangePicker.sass";
import calendarImg from "../../assets/img/calender.svg";
import {enumerateDaysBetweenDates, isBlocked} from "../../util/date";

const RvDateRangePicker = ({ isLocationChanged, onChange, startDate, endDate, bookedDatesData }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [bookedDates, setBookedDates] = useState(null);

  const dateRangeChanged = (start, end) => {
    if (start) {
      onChange({ startDate: start?.startDate, endDate: start.endDate});
    }
    if (end) {
      onChange({ startDate: end.startDate, endDate: end.endDate});
    }
  }

  const onFocusChange = (focusedInput) => {
    updateBlockedDates();
    setFocusedInput(focusedInput);
  }

  const handleSize = () => {
    setWidth(window.innerWidth);
  }

  const updateBlockedDates = () => {
    let newDates = [];
    bookedDatesData?.map(date => {
      if (date.from && date.to) {
        const dates = enumerateDaysBetweenDates(date.from, date.to);
        if (dates?.length > 0)
          newDates = newDates.concat(dates);
      }
    });
    setBookedDates(newDates);
  }

  useEffect(() => {
    updateBlockedDates();
  }, [bookedDatesData, focusedInput])

  useEffect(() => {
    if (isLocationChanged === true) {
      if (!startDate && !endDate)
        setFocusedInput("startDate");
    }
  }, [isLocationChanged])

  useEffect(() => {
    window.addEventListener("resize", handleSize, false);
  }, [])

  return (
    <div className="wrap">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        startDateId="start_date_id"
        endDateId="end_date_id"
        startDatePlaceholderText="Pickup"
        endDatePlaceholderText="Return"
        onDatesChange={(start, end) => dateRangeChanged(start, end)}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        orientation={width > 670 ? "horizontal" : "vertical"}
        isDayBlocked={day => isBlocked(day, bookedDates)}
        onPrevMonthClick={updateBlockedDates}
        onNextMonthClick={updateBlockedDates}
      />
      <img className="img leftImg" src={calendarImg} alt="calender"/>
      <img className="img rightImg" src={calendarImg} alt="calender"/>
    </div>
  )
}

export default RvDateRangePicker