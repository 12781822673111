import axios from "axios";
import {
  resListFetchSuccess,
  resListFetchFailure
} from "../actions/resAction";
import {API_URL} from "../config/config";

export const findList = (params = null) => {
  return (dispatch) => {
    axios.get(API_URL + 'res/findList', {params})
      .then((response) => {
        if (response?.data)
          dispatch(resListFetchSuccess(response.data));
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          dispatch(resListFetchFailure(error.response.data.error));
        }
      })
  }
}

export const findDateResList = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + "res/findDateResList", {params})
      .then(response => {
        if (response?.data?.data)
          resolve(response.data.data);
        else
          resolve();
      })
      .catch(error => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong");
      })
  });
}

export const saveEmployeeCash = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_URL + 'res/employeeCash', {data: data})
      .then((response) => {
        if (response?.data?.success)
          resolve();
        else
          reject("Something went wrong!");
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const getEmployeeCash = (date) => {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + 'res/employeeCash', {params: {date}})
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const updateEmployeeCash = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(API_URL + 'res/employeeCash/' + id, {data: data})
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const deleteEmployeeCash = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(API_URL + 'res/employeeCash/' + id)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      })
  })
}

export const saveMoneyBank = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_URL + 'res/employeeCash/moneySentToBank', {data})
      .then((response) => {
        if (response?.data?.data)
          resolve();
        else
          reject('Something went wrong!');
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const getMoneyBank = (date) => {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + 'res/employeeCash/moneySentToBank', {params: {date}})
      .then((response) => {
        resolve(response?.data?.data)
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}
export const getEmployeeCash1 = (date) => {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + "res/employeeCash1", { params: {date} })
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const getGeneralComments = (date) => {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + "res/generalComments", { params: {date} })
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error.response.data.error);
        else
          reject("Something went wrong");
      });
  });
}

export const saveGeneralComments = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_URL + "res/generalComments", {data: data})
      .then((response) => {
        if (response?.data?.success)
          resolve();
        else
          reject("Something went wrong!");
      })
      .catch((error) => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const updateGeneralComment = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(API_URL + "res/generalComments/" + id, {data: data})
      .then(response => {
        resolve(response?.data?.data);
      })
      .catch(error => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const deleteGeneralComment = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(API_URL + "res/generalComments/" + id)
      .then(response => {
        resolve(response?.data?.data);
      })
      .catch(error => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}

export const getLastWeekComments = (date) => {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + "res/findLastWeekComments", { params: {date} })
      .then(response => {
        resolve(response?.data?.data);
      })
      .catch(error => {
        if (error?.response?.data?.error)
          reject(error?.response?.data?.error);
        else
          reject("Something went wrong!");
      });
  });
}