import React, {useEffect, useState} from "react";
import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css";
import { DayPickerRangeController } from "react-dates";
import "./rvEmbedDateRangePicker.sass";
import {isInclusivelyAfterDay, isBlocked, enumerateDaysBetweenDates} from "../../util/date";

const RvEmbedDateRangePicker = ({ startDate, endDate, setStartDate, setEndDate, bookedDatesData }) => {
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [width, setWidth] = useState(window.innerWidth);
  const [bookedDates, setBookedDates] = useState([]);

  const handleSize = () => {
    setWidth(window.innerWidth);
  }

  const updateBlockedDates = () => {
    let newDates = [];
    bookedDatesData?.map(date => {
      if (date.from && date.to) {
        const dates = enumerateDaysBetweenDates(date.from, date.to);
        if (dates?.length > 0)
          newDates = newDates.concat(dates);
      }
    });
    setBookedDates(newDates);
  }

  useEffect(() => {
    updateBlockedDates();
  }, [bookedDatesData])

  useEffect(() => {
    window.addEventListener("resize", handleSize, false);
  }, [])

  return (
    <DayPickerRangeController
      numberOfMonths={2}
      startDate={startDate}
      endDate={endDate}
      focusedInput={focusedInput}
      orientation={width > 670 ? "horizontal" : "vertical"}
      isOutsideRange={day => !isInclusivelyAfterDay(day)}
      isDayBlocked={day => isBlocked(day, bookedDates)}
      onDatesChange={(value) => {
        setStartDate(value?.startDate);
        setEndDate(value?.endDate);
      }}
      onFocusChange={focusedInput => {
        if (!focusedInput) {
          setFocusedInput("startDate");
          return;
        }
        setFocusedInput(focusedInput);
      }}
      onPrevMonthClick={updateBlockedDates}
      onNextMonthClick={updateBlockedDates}
    />
  )
}

export default RvEmbedDateRangePicker