import React from "react";
import cn from "classnames";
import styles from "./rvPopular.module.sass";
import RvCard from "../rvCard";


const RvPopular = ({ rvs }) => {
  return (
    rvs?.length > 0 &&
    <div className={cn("py-4", "py-md-5", styles.popular)}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={cn("text-center", "mb-4", styles.header)}>
              <h3 className={styles.label}>
                Top <span className={styles.small}>RVs</span> nearby
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          {rvs.map((item, index) => {
            return (
              <div className="col-12 col-md-3 mb-5 mb-md-0 d-flex justify-content-center" key={index}>
                <RvCard
                  data={item}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RvPopular