import React  from "react";
import Collapsible from "react-collapsible";
import "./rvCollapse.sass"

const RvCollapse = ({trigger, body, setIsOpened}) => {

  return (
    <Collapsible
      trigger={trigger}
      transitionTime={250}
      onOpening={() => setIsOpened(true)}
      onClosing={() => setIsOpened(false)}
    >
      { body }
    </Collapsible>
  )
}

export default RvCollapse