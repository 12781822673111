export const RVS_SEARCH_FETCH = "RVS_SEARCH_FETCH"
export const RVS_SEARCH_FETCH_SUCCESS = "RVS_SEARCH_FETCH_SUCCESS"
export const RVS_SEARCH_FETCH_FAILURE = "RVS_SEARCH_FETCH_FAILURE"
export const RVS_POPULAR_NEAR_BY_FETCH = "RVS_POPULAR_NEAR_BY"
export const RVS_POPULAR_NEAR_BY_FETCH_SUCCESS = "RVS_POPULAR_NEAR_BY_FETCH_SUCCESS"
export const RVS_POPULAR_NEAR_BY_FETCH_FAILURE = "RVS_POPULAR_NEAR_BY_FETCH_FAILURE"
export const RVS_ITEM_FETCH = "RVS_ITEM_FETCH"
export const RVS_ITEM_FETCH_SUCCESS = "RVS_ITEM_FETCH_SUCCESS"
export const RVS_ITEM_FETCH_FAILURE = "RVS_ITEM_FETCH_FAILURE"
export const RVS_ITEM_BOOK = 'RVS_ITEM_BOOK'
export const RVS_ITEM_BOOK_SUCCESS = 'RVS_ITEM_BOOK_SUCCESS'
export const RVS_ITEM_BOOK_FAILURE = 'RVS_ITEM_BOOK_FAILURE'
export const RES_LIST_FETCH = 'RES_LIST_FETCH'
export const RES_LIST_FETCH_SUCCESS = 'RES_LIST_FETCH_SUCCESS'
export const RES_LIST_FETCH_FAILURE = 'RES_LIST_FETCH_FAILURE'