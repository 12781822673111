import React, { useState } from "react";
import cn from "classnames";
import styles from "./amenitiesPopup.module.sass";
import {
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiOutlineUsergroupAdd,
  AiOutlineContainer,
  AiFillFilter,
  AiOutlineDesktop,
  AiOutlineAudio,
  AiOutlineWifi
} from "react-icons/ai";
import { BiBed } from "react-icons/bi";
import { CgSmartHomeRefrigerator, CgSmartHomeWashMachine } from "react-icons/cg";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import {
  GiHotSpices,
  GiPowerGenerator,
  GiSolarPower,
  GiDutchBike,
  GiWindow,
} from "react-icons/gi";
import { MdKitchen } from "react-icons/md";
import { SiAirtable, SiOpslevel } from "react-icons/si";
import { FiThermometer } from "react-icons/fi";
import { GrFan } from "react-icons/gr";
import { WiShowers } from "react-icons/wi";
import { FaShower, FaToilet } from "react-icons/fa";
import { IoWaterOutline } from "react-icons/io5";
import { CgArrowsExchangeAlt } from "react-icons/cg";

const ICON_COLOR = "#1fa1a1";
const AmenitiesPopup = ({ className }) => {
  const [seatbelts, setSeatbelts] = useState(0);
  const [sleeps, setSleeps] = useState(0);
  const [refrigerator, setRefrigerator] = useState(false);
  const [microwave, setMicrowave] = useState(false);
  const [oven, setOven] = useState(false);
  const [introductionStove, setIntroductionStove] = useState(false);
  const [kitchenSink, setKitchenSink] = useState(false);
  const [diningTable, setDiningTable] = useState(false);
  const [airConditioning, setAirConditioning] = useState(false);
  const [heater, setHeater] = useState(false);
  const [ceilingFan, setCeilingFan] = useState(false);
  const [indoorShower, setIndoorShower] = useState(false);
  const [outdoorShower, setOutdoorShower] = useState(false);
  const [toilet, setToilet] = useState(false);
  const [generator, setGenerator] = useState(false);
  const [inverter, setInverter] = useState(false);
  const [waterTank, setWaterTank] = useState(false);
  const [levelingJacks, setLevelingJacks] = useState(false);
  const [wifi, setWifi] = useState(false);
  const [audioInputs, setAudioInputs] = useState(false);
  const [awning, setAwning] = useState(false);
  const [bikeRack, setBikeRack] = useState(false);
  const [solarPanel, setSolarPanel] = useState(false);
  const [tvDvd, setTvDvd] = useState(false);
  const [washerDryer, setWasherDryer] = useState(false);

  return (
    <div className={cn(className, styles.main)}>
      <div className={styles.content}>
        <div className={styles.body}>
          <div className={styles.list}>
            <div className={styles.items}>
              <div>
                <fieldset className={styles.item}>
                  <h5 className={styles.category}>Sit / Sleep</h5>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <AiOutlineMinusCircle
                        className={styles.icon}
                        size={22}
                        color={ICON_COLOR}
                        onClick={(e) => seatbelts > 0 && setSeatbelts(prevState => --prevState)}
                      />
                      <input
                        className={styles.input}
                        type="number"
                        min="0"
                        max="10"
                        step="1"
                        value={seatbelts}
                        onChange={(e) => setSeatbelts(e.target.value)}
                      />
                      <AiOutlinePlusCircle
                        className={styles.icon}
                        size={22}
                        color={ICON_COLOR}
                        onClick={(e) => seatbelts < 10 && setSeatbelts(prevState => ++prevState)}
                      />
                      <span className={styles.label}>
                        <AiOutlineUsergroupAdd
                          className={styles.labelIcon}
                          size={22}
                        />
                        Seatbelts
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <AiOutlineMinusCircle
                        className={styles.icon}
                        size={22}
                        color={ICON_COLOR}
                        onClick={() => sleeps > 0 && setSleeps(prevState => --prevState)}
                      />
                      <input
                        className={styles.input}
                        type="number"
                        min="0"
                        max="10"
                        step="1"
                        value={sleeps}
                        onChange={(e) => setSleeps(e.target.value)}
                      />
                      <AiOutlinePlusCircle
                        className={styles.icon}
                        size={22}
                        color={ICON_COLOR}
                        onClick={() => sleeps < 10 && setSleeps(prevState => ++prevState)}
                      />
                      <span className={styles.label}>
                        <BiBed
                          className={styles.labelIcon}
                          size={22}
                        />
                        Sleeps
                      </span>
                    </div>
                  </div>
                </fieldset>
                <fieldset className={styles.item}>
                  <h5 className={styles.category}>Cooking</h5>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <CgSmartHomeRefrigerator
                            className={styles.labelIcon}
                            size={22}
                          />
                          Regrigerator
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={refrigerator}
                          checked={refrigerator}
                          onChange={(e) => setRefrigerator(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <HiOutlineDesktopComputer
                            className={styles.labelIcon}
                            size={22}
                          />
                          Microwave
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={microwave}
                          checked={microwave}
                          onChange={(e) => setMicrowave(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <AiOutlineContainer
                            className={styles.labelIcon}
                            size={22}
                          />
                          Oven
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={oven}
                          checked={oven}
                          onChange={(e) => setOven(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiHotSpices
                            className={styles.labelIcon}
                            size={22}
                          />
                          Induction stove
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={introductionStove}
                          checked={introductionStove}
                          onChange={(e) => setIntroductionStove(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <MdKitchen
                            className={styles.labelIcon}
                            size={22}
                          />
                          Kitchen sink
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={kitchenSink}
                          checked={kitchenSink}
                          onChange={(e) => setKitchenSink(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <SiAirtable
                            className={styles.labelIcon}
                            size={22}
                          />
                          Dining table
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={diningTable}
                          checked={diningTable}
                          onChange={(e) => setDiningTable(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div>
                <fieldset className={styles.item}>
                  <h5 className={styles.category}>Temperature</h5>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <AiFillFilter
                            className={styles.labelIcon}
                            size={22}
                          />
                          Air conditioning
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={airConditioning}
                          checked={airConditioning}
                          onChange={(e) => setAirConditioning(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <FiThermometer
                            className={styles.labelIcon}
                            size={22}
                          />
                          Heater
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={heater}
                          checked={heater}
                          onChange={(e) => setHeater(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GrFan
                            className={styles.labelIcon}
                            size={22}
                          />
                          Ceiling fan
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={ceilingFan}
                          checked={ceilingFan}
                          onChange={(e) => setCeilingFan(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </fieldset>
                <fieldset className={styles.item}>
                  <h5 className={styles.category}>Bathroom</h5>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <WiShowers
                            className={styles.labelIcon}
                            size={22}
                          />
                          Indoor shower
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={indoorShower}
                          checked={indoorShower}
                          onChange={(e) => setIndoorShower(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <FaShower
                            className={styles.labelIcon}
                            size={22}
                          />
                          Outdoor shower
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={outdoorShower}
                          checked={outdoorShower}
                          onChange={(e) => setOutdoorShower(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <FaToilet
                            className={styles.labelIcon}
                            size={22}
                          />
                          Toilet
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={toilet}
                          checked={toilet}
                          onChange={(e) => setToilet(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div>
                <fieldset className={styles.item}>
                  <h5 className={styles.category}>Other</h5>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiPowerGenerator
                            className={styles.labelIcon}
                            size={22}
                          />
                          Generator
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={generator}
                          checked={generator}
                          onChange={(e) => setGenerator(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <CgArrowsExchangeAlt
                            className={styles.labelIcon}
                            size={22}
                          />
                          Inverter
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={inverter}
                          checked={inverter}
                          onChange={(e) => setInverter(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <IoWaterOutline
                            className={styles.labelIcon}
                            size={22}
                          />
                          Water tank
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={waterTank}
                          checked={waterTank}
                          onChange={(e) => setWaterTank(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <SiOpslevel
                            className={styles.labelIcon}
                            size={22}
                          />
                          Leveling jacks
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={levelingJacks}
                          checked={levelingJacks}
                          onChange={(e) => setLevelingJacks(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <AiOutlineWifi
                            className={styles.labelIcon}
                            size={22}
                          />
                          Wifi
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={wifi}
                          checked={wifi}
                          onChange={(e) => setWifi(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <AiOutlineAudio
                            className={styles.labelIcon}
                            size={22}
                          />
                          Audio inputs
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={audioInputs}
                          checked={audioInputs}
                          onChange={(e) => setAudioInputs(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiWindow
                            className={styles.labelIcon}
                            size={22}
                          />
                          Awning
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={awning}
                          checked={awning}
                          onChange={(e) => setAwning(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiDutchBike
                            className={styles.labelIcon}
                            size={22}
                          />
                          Bike rack
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={bikeRack}
                          checked={bikeRack}
                          onChange={(e) => setBikeRack(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <GiSolarPower
                            className={styles.labelIcon}
                            size={22}
                          />
                          Solar panel
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={solarPanel}
                          checked={solarPanel}
                          onChange={(e) => setSolarPanel(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <AiOutlineDesktop
                            className={styles.labelIcon}
                            size={22}
                          />
                          TV/DVD
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={tvDvd}
                          checked={tvDvd}
                          onChange={(e) => setTvDvd(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label className={styles.checkContainer}>
                        <span className={styles.labelTitle}>
                          <CgSmartHomeWashMachine
                            className={styles.labelIcon}
                            size={22}
                          />
                          Washer dryer
                      </span>
                        <input
                          className={styles.checkInput}
                          type="checkbox"
                          value={washerDryer}
                          checked={washerDryer}
                          onChange={(e) => setWasherDryer(e.target.checked)}
                        />
                        <span className={styles.checkMark}></span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <hr className={styles.border} />
        </div>
        <div className={styles.footer}>
          <button className={styles.btn}>
            No results
          </button>
          <span
            className={styles.txt}
            onClick={() => {
              setSeatbelts(0);
              setSleeps(0);
              setRefrigerator(false);
              setMicrowave(false);
              setOven(false);
              setKitchenSink(false);
              setDiningTable(false);
              setAirConditioning(false);
              setHeater(false);
              setCeilingFan(false);
              setIndoorShower(false);
              setOutdoorShower(false);
              setToilet(false);
              setGenerator(false);
              setInverter(false);
              setWaterTank(false);
              setLevelingJacks(false);
              setWifi(false);
              setAudioInputs(false);
              setAwning(false);
              setBikeRack(false);
              setSolarPanel(false);
              setTvDvd(false);
              setWasherDryer(false);
              setIntroductionStove(false);
            }}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default AmenitiesPopup