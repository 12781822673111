import React from 'react';
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Home from "./pages/home";
import Search from "./pages/search";
import ListYourRv from "./pages/listYourRv";
import Detail from "./pages/detail";
import About from "./pages/about";
import Book from "./pages/book";
import Res from "./pages/res";
import DateRes from "./pages/dateRes";
import EmployeeCash from "./pages/employeeCash";
import EmployeeCash1 from "./pages/employeeCash1";
import QuickBooksButton from "./pages/quickBooksButton";

import { GOOGLE_ANALYTICS_TRACKING_ID } from "./config/config";

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route exact path="/home" component={Home} />
          <Route path="/search" component={Search} />
          <Route exact path="/list-your-rv" component={ListYourRv} />
          <Route exact path="/detail/:id" component={Detail} />
          <Route exact path="/about" component={About} />
          <Route exact path="/book" component={Book} />
          <Route exact path="/res" component={Res} />
          <Route exact path="/employeeCash" component={EmployeeCash} />
          <Route exact path="/employeeCash1" component={EmployeeCash1} />
          <Route exact path="/dateRes" component={DateRes} />
          <Route exact path="/quickBooks" component={QuickBooksButton} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
