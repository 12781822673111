import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./res.module.sass";
import "./res.sass";
import { useQuery, getSno, getQParams } from "../../util/url";
import * as resService from "../../services/resService";

const Res = ({data, error, actions}) => {
  const query = useQuery();
  const [snoValue, setSNOValue] = useState(0);

  useEffect(() => {
    if (query) {
      const sno = getSno(query);
      const qParams = getQParams(query);
      if (sno) {
        setSNOValue(Number(sno));
        actions.findList({sno, ...qParams});
        setInterval(() => {
          actions.findList({sno, ...qParams});
        }, 10000)
      } else {
        setInterval(() => {
          actions.findList();
        }, 10000)
      }

    } else {
      actions.findList();
      setInterval(() => {
        actions.findList();
      }, 5000);
    }
  }, [])

  return (
    <div className={styles.resWrap}>
      {!(data || error) && <div className={styles.loadingContainer}><ReactLoading type="spin" color="#23B5B5" height={50} width={50} /></div>}
      <div className={styles.resHeaderWrap}>
        <h3>{data?.data?.title || " "}</h3>
      </div>
      <table className={snoValue === 1 ? styles.snoTable : styles.resTable}>
        <thead>
          <tr>
            {data?.data?.data?.length > 0 && Object.keys(data.data.data[0]).map((key, index) => {
              return <th key={index}>{key}</th>
            })}
          </tr>
        </thead>
        <tbody>
        {data?.data?.data?.map((row, rowIndex) => {
          return <tr key={rowIndex}>
            {Object.entries(row).map(column => {
              return <td key={column[0]} dangerouslySetInnerHTML={{__html: column[1]}} />
            })}
          </tr>
        })}
        </tbody>
      </table>
      {error && (
        <div>
          <h4>Error:</h4>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.res.data,
  error: state.res.errorMessage
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, resService), dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Res);