import { GOOGLE_RECAPTCHA_URL } from "../config/config";

export const loadScriptByURL = () => {
  if (!GOOGLE_RECAPTCHA_URL) return;
  const id = "recaptcha-key";
  const isScriptExist = document.getElementById(id);
  if (!isScriptExist) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = GOOGLE_RECAPTCHA_URL;
    script.id = id;
    script.onload = () => {

    }
    document.body.appendChild(script);
  }
}