import React from "react"
import cn from "classnames";
import {Link} from "react-router-dom";
import styles from "./rvRentz.module.sass";
import rewardImg from "../../assets/img/reward.png";
import userImg from "../../assets/img/user.png";
import supportImg from "../../assets/img/support.png";
import smileImg from "../../assets/img/smile.png";

const RvRentz = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={cn("bg-white", "p-4", "rounded", "d-flex", styles.topBottom)}>
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-between w-100">
                <div className={cn("px-md-1", "mb-3", "mb-md-0", styles.wrap)}>
                  <img src={rewardImg} alt="" />
                  <span className={styles.label}>Worry-free hire insurance</span>
                </div>
                <div className={cn("px-md-1", "mb-3", "mb-md-0", styles.wrap)}>
                  <img src={userImg} alt="" />
                  <span className={styles.label}>All suppliers are verified Copy </span>
                </div>
                <div className={cn("px-md-1", "mb-3", "mb-md-0", styles.wrap)}>
                  <img src={supportImg} alt="" />
                  <span className={styles.label}>Fast & reliable customer support</span>
                </div>
                <div className={cn("px-md-1", "mb-3", "mb-md-0", styles.wrap)}>
                  <img src={smileImg} alt="" />
                  <span className={styles.label}>5000+ happy travellers every month</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={styles.rentz}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className={cn("text-center", "text-xl-left", styles.wrap)}>
                <p className={styles.line}>
                  <span className={styles.small}>Own an RV?</span>
                </p>
                <h3 className={cn("mb-3", styles.large)}>
                  Join RENTZLY for free and earn up to $45, 000 per year!
                </h3>
                <p className={styles.line}>
                  No signup fees, no long-term commitment and a fully transparent cooperation agreement. Sign up now
                  and we get in touch with you.
                </p>
                <Link to="/list-your-rv" className={cn("text-white", "py-3", "px-5", "mt-4", "d-inline-block", styles.btn)}>
                  List your RV
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RvRentz