import axios from "axios";
import { API_URL } from "../config/config";

export const createTicket = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_URL + 'tickets', data)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject();
      })
  })
}