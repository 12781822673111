import React, { useRef, useState } from "react";
import { GoogleMap, withGoogleMap } from "react-google-maps";
import { GiElectric } from "react-icons/gi";
import RvMapInfoBox from "../rvMapInfoBox";
import transparentImg from "../../assets/images/transpareent.png"

const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const options = {
  defaultZoom: 8,
  defaultCenter: { lat: 40.739, lng: -74.042 },
  streetViewControl: false,
  scrollwheel: true,
  disableDefaultUI: true,
  markerLabel: {
    style: {
      color: "#ce6262",
      backgroundColor: "#fff",
      fontSize: "0.9rem",
      fontWeight: "bold",
      padding: "0.4rem 0.6rem",
      border: "0.1rem solid #ddd",
      borderRadius: "2rem"
    },
    label: {
      style: { display: "flex", alignItems: "center" },
      size: 18
    }
  }
}

const RvNewMap = ({ location, data, centerChanged }) => {
  const mapRef = useRef(null);
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);
  const [infoBoxLocation, setInfoBoxLocation] = useState(null);
  const [clickedMarkIndex, setClickedMarkIndex] = useState(null);

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={options.defaultZoom}
      defaultCenter={location?.lat && location?.lng ? location : options.defaultCenter}
      center={location?.lat && location?.lng ? location : options.defaultCenter}
      options={{
        streetViewControl: options.streetViewControl,
        scrollwheel: options.scrollwheel, // allow scroll wheel
        disableDefaultUI: options.disableDefaultUI,
      }}
      onClick={() => {
        setInfoBoxVisible(false);
        setClickedMarkIndex(null);
        setInfoBoxLocation(null);
      }}
      onCenterChanged={() => {
        if (mapRef?.current?.getCenter()?.toJSON())
          centerChanged(mapRef.current.getCenter().toJSON());
      }}
    >
      {data?.data?.map((item, index) => {
        return (
          <MarkerWithLabel
            key={index}
            position={{ lat: item.lat, lng: item.lng }}
            labelAnchor={ new window.google.maps.Point(0, 0) }
            labelStyle={options.markerLabel.style}
            icon={{ url: transparentImg }}
            onClick={() => {
              setInfoBoxLocation({ lat: item.lat, lng: item.lng });
              setClickedMarkIndex(index);
              setInfoBoxVisible(true);
            }}
          >
            <span style={ options.markerLabel.label.style }>
              <GiElectric size={ options.markerLabel.label.size } />${ item.price_per_day / 100 || "0" }
            </span>
          </MarkerWithLabel>
        )
      })}
      {infoBoxVisible &&
        <RvMapInfoBox
          location={ infoBoxLocation }
          data={ data?.data[clickedMarkIndex] }
        />
      }
    </GoogleMap>
  )
}

export default withGoogleMap(RvNewMap)

