import React from "react";
import cn from "classnames";
import {Link} from "react-router-dom";
import styles from "./rvHowItWorks.module.sass";
import howItWorksImg from "../../assets/img/how-it-work.png";

const RvHowItWorks = () => {
  return (
    <div className={cn("py-4", "py-md-5", styles.howItWorks)}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={cn("bg-white", "shadow", "p-4", styles.wrap)}>
              <div className={cn("text-center", "mb-4", styles.header)}>
                <h3 className={styles.large}>Here's how it works.</h3>
              </div>
              <div className="how-it-work-img text-center">
                <img src={howItWorksImg} alt="work" />
              </div>
              <div className="py-4">
                <Link className={cn(styles.btn, styles.rentRv)} to="/search">
                  Rent an RV
                </Link>
                <Link className={cn("ml-3", styles.btn, styles.listRv)} to="/list-your-rv">
                  List my RV
                </Link>
              </div>
              <div className={styles.numberWrap}>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <div className="mr-4">
                    <div className={cn("d-flex", "align-items-center", styles.topWrap)}>
                      <p className={cn("mb-1", styles.label)}>1.</p>
                      <span className={cn("d-block", styles.small)}>

                      </span>
                    </div>
                    <h3 className={styles.large}>Find the perfect RV</h3>
                    <p className={cn("font-weight-light", styles.description)}>
                      Search for RVs with the amenities you want near your destination.
                    </p>
                  </div>
                  <div className="mr-4">
                    <div className={cn("d-flex", "align-items-center", styles.topWrap)}>
                      <p className={cn("mb-1", styles.label)}>2.</p>
                      <span className={cn("d-block", styles.small)}>

                        </span>
                    </div>
                    <h3 className={styles.large}>Book your RV</h3>
                    <p className={cn("font-weight-light", styles.description)}>
                      Search for RVs with the amenities you want near your destination.
                    </p>
                  </div>
                  <div className="mr-4">
                    <div className={cn("d-flex", "align-items-center", styles.topWrap)}>
                      <p className={cn("mb-1", styles.label)}>3.</p>
                      <span className={cn("d-block", styles.small)}>

                        </span>
                    </div>
                    <h3 className={styles.large}>Pick-up or have it delivered</h3>
                    <p className={cn("font-weight-light", styles.description)}>
                      Search for RVs with the amenities you want near your destination.
                    </p>
                  </div>
                  <div className="mr-4">
                    <div className={cn("d-flex", "align-items-center", styles.topWrap)}>
                      <p className={cn("mb-1", styles.label)}>4.</p>
                      <span className={cn("d-block", styles.small)}>

                        </span>
                    </div>
                    <h3 className={styles.large}>Hit the road!</h3>
                    <p className={cn("font-weight-light", styles.description)}>
                      Search for RVs with the amenities you want near your destination.
                    </p>
                  </div>
                  <div>
                    <div className={cn("d-flex", "align-items-center", styles.topWrap)}>
                      <p className={cn("mb-1", styles.label)}>5.</p>
                      <span className={cn("d-block", styles.small)}>

                        </span>
                    </div>
                    <h3 className={styles.large}>Return</h3>
                    <p className={cn("font-weight-light", styles.description)}>
                      Search for RVs with the amenities you want near your destination.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RvHowItWorks