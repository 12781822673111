import React, {useState} from "react";
import styles from "./towablePopup.module.sass";
import cn from "classnames";
import {CgCheck} from "react-icons/cg";
import {AiOutlineCar} from "react-icons/ai";

const ICON_SIZE = 28;
const ACTIVE_COLOR = "#1fa1a1";
const towableData = {
  travelTrailer: {
    id: "towable-filter-option-travel-trailer",
    title: "Travel Trailer",
    description: "Trailers for all types of towing vehicles, including SUVs and pickups.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  fifthWheel: {
    id: "towable-filter-option-fifth-wheel",
    title: "Fifth-Wheel",
    description: "Larger trailers that attach to a gooseneck extension in the truck bed.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  toyHauler: {
    id: "towable-filter-option-toy-hauler",
    title: "Toy Hauler",
    description: "Living quarters in the front with space for hauling “toys” in the back.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  foldingTrailer: {
    id: "towable-filter-option-folding-trailer",
    title: "Folding Trailer",
    description: "A great way to transform average cars into adventure cars.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  },
  utilityTrailer: {
    id: "towable-filter-option-utility-trailer",
    title: "Utility Trailer",
    description: "All other types of towable trailers.",
    icon: <AiOutlineCar size={ICON_SIZE} />
  }
}
const TowablePopup = ({ className }) => {
  const [travelTrailer, setTravelTrailer] = useState(false);
  const [fifthWheel, setFifthWheel] = useState(false);
  const [toyHauler, setToyHauler] = useState(false);
  const [foldingTrailer, setFoldingTrailer] = useState(false);
  const [utilityTrailer, setUtilityTrailer] = useState(false);

  return (
    <div className={cn(className, styles.main)}>
      <div className={styles.content}>
        <div className={styles.body}>
          <div className={styles.list}>
            <div className={styles.items}>
              <input
                className={styles.input}
                type="checkbox"
                id={ towableData.travelTrailer.id }
                onChange={(e) => {
                  setTravelTrailer(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, travelTrailer ? styles.active : "")}
                htmlFor={ towableData.travelTrailer.id }
              >
                <div className={styles.header}>
                  { towableData.travelTrailer.icon }
                  {travelTrailer ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ towableData.travelTrailer.title }</p>
                <p className={styles.description}>
                  { towableData.travelTrailer.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ towableData.fifthWheel.id }
                onChange={(e) => {
                  setFifthWheel(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, fifthWheel ? styles.active : "")}
                htmlFor={ towableData.fifthWheel.id }
              >
                <div className={styles.header}>
                  { towableData.fifthWheel.icon }
                  {fifthWheel ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ towableData.fifthWheel.title }</p>
                <p className={styles.description}>
                  { towableData.fifthWheel.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ towableData.toyHauler.id }
                onChange={(e) => {
                  setToyHauler(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, toyHauler ? styles.active : "")}
                htmlFor={ towableData.toyHauler.id }
              >
                <div className={styles.header}>
                  { towableData.toyHauler.icon }
                  {toyHauler ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ towableData.toyHauler.title }</p>
                <p className={styles.description}>
                  { towableData.toyHauler.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ towableData.foldingTrailer.id }
                onChange={(e) => {
                  setFoldingTrailer(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, foldingTrailer ? styles.active : "")}
                htmlFor={ towableData.foldingTrailer.id }
              >
                <div className={styles.header}>
                  { towableData.foldingTrailer.icon }
                  {foldingTrailer ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ towableData.foldingTrailer.title }</p>
                <p className={styles.description}>
                  { towableData.foldingTrailer.description }
                </p>
              </label>
              <input
                className={styles.input}
                type="checkbox"
                id={ towableData.utilityTrailer.id }
                onChange={(e) => {
                  setUtilityTrailer(e.target.checked);
                }}
              />
              <label
                className={cn(styles.label, utilityTrailer ? styles.active : "")}
                htmlFor={ towableData.utilityTrailer.id }
              >
                <div className={styles.header}>
                  { towableData.utilityTrailer.icon }
                  {utilityTrailer ?
                    <CgCheck color={ACTIVE_COLOR} /> : <div className={styles.icon}></div>
                  }
                </div>
                <p className={styles.title}>{ towableData.utilityTrailer.title }</p>
                <p className={styles.description}>
                  { towableData.utilityTrailer.description }
                </p>
              </label>
            </div>
            <div
              className={styles.selectAllWrapper}
              onClick={() => {
                setTravelTrailer(true);
                setFifthWheel(true);
                setToyHauler(true);
                setFoldingTrailer(true);
                setUtilityTrailer(true);
              }}
            >
              <span className={styles.btn}>
                Select All
              </span>
            </div>
          </div>
          <hr className={styles.border} />
        </div>
        <div className={styles.footer}>
          <button className={styles.btn}>
            No results
          </button>
          <span
            className={styles.txt}
            onClick={() => {
              setTravelTrailer(false);
              setFifthWheel(false);
              setToyHauler(false);
              setFoldingTrailer(false);
              setUtilityTrailer(false);
            }}
          >
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default TowablePopup