import React, { useState }  from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import OutsideClickHandler from "react-outside-click-handler";
import "./subHeader.sass";
import DrivablePopup from "../drivablePopup";
import TowablePopup from "../towable";
import PricePopup from "../pricePopup";
import DeliveryPopup from "../deliveryPopup";
import AmenitiesPopup from "../amenitiesPopup";
import MorePopup from "../morePopup";

const SubHeader = ({ priceSort, distanceSort, setPriceSort, setDistanceSort }) => {
  const [visibleDrivable, setVisibleDrivable] = useState(false);
  const [visibleTowable, setVisibleTowable] = useState(false);
  const [visiblePrice, setVisiblePrice] = useState(false);
  const [visibleDelivery, setVisibleDelivery] = useState(false);
  const [visibleAmenities, setVisibleAmenities] = useState(false);
  const [visibleMore, setVisibleMore] = useState(false);

  return (
    <div className="main">
      <ul>
        <li onClick={() => setVisibleDrivable(prevState => !prevState)}>
          Drivable &nbsp;
          { !visibleDrivable ? <IoMdArrowDropdown /> : <IoMdArrowDropup /> }
        </li>
        <li onClick={() => setVisibleTowable(prevState => !prevState)}>
          Towable &nbsp;
          { !visibleTowable ? <IoMdArrowDropdown /> : <IoMdArrowDropup /> }
        </li>
        <li onClick={() => setVisiblePrice(prevState => !prevState)}>
          Price &nbsp;
          { !visiblePrice ? <IoMdArrowDropdown /> : <IoMdArrowDropup /> }
        </li>
        <li onClick={() => setVisibleDelivery(prevState => !prevState)}>
          Delivery &nbsp;
          { !visibleDelivery ? <IoMdArrowDropdown /> : <IoMdArrowDropup /> }
        </li>
        <li onClick={() => setVisibleAmenities(prevState => !prevState)}>
          Amenities &nbsp;
          { !visibleAmenities ? <IoMdArrowDropdown /> : <IoMdArrowDropup /> }
        </li>
        <li onClick={() => setVisibleMore(prevState => !prevState)}>
          More &nbsp;
          { !visibleMore ? <IoMdArrowDropdown /> : <IoMdArrowDropup /> }
        </li>
      </ul>
      {visibleDrivable &&
        <OutsideClickHandler onOutsideClick={() => setVisibleDrivable(false)}>
          <DrivablePopup className="popup" />
        </OutsideClickHandler>
      }
      {visibleTowable &&
        <OutsideClickHandler onOutsideClick={() => setVisibleTowable(false)}>
          <TowablePopup className="popup" />
        </OutsideClickHandler>
      }
      {visiblePrice &&
        <OutsideClickHandler onOutsideClick={() => setVisiblePrice(false)}>
          <PricePopup className="popup" />
        </OutsideClickHandler>
      }
      {visibleDelivery &&
        <OutsideClickHandler onOutsideClick={() => setVisibleDelivery(false)}>
          <DeliveryPopup className="popup" />
        </OutsideClickHandler>
      }
      {visibleAmenities &&
        <OutsideClickHandler onOutsideClick={() => setVisibleAmenities(false)}>
          <AmenitiesPopup className="popup" />
        </OutsideClickHandler>
      }
      {visibleMore &&
        <OutsideClickHandler onOutsideClick={() => setVisibleMore(false)}>
          <MorePopup className="popup" />
        </OutsideClickHandler>
      }
    </div>
  )
}

export default SubHeader