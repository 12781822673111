import React, {useEffect, useRef} from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { IoMdClose } from "react-icons/io";
import styles from "./rvModal.module.sass";

const ICON_COLOR = "#23B5B5";

const RvModal = ({
  visible,
  outerClassName,
  containerClassName,
  onClose,
  children,
  classes
}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    visible ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  }, [visible])

  return createPortal(
    visible && (
      <div className={styles.modal} ref={scrollRef}>
        <div className={cn(styles.outer, outerClassName, classes)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            <div className={containerClassName}>
              {children}
              <button className={styles.close} onClick={onClose}>
                <IoMdClose color={ICON_COLOR} size={24} />
              </button>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  )
}

export default RvModal